import { style_base } from "@src/common";
import { generateString } from "@src/common/util/generators";
import Swiper, { Navigation, Pagination, SwiperOptions, Manipulation } from "swiper";

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/manipulation';
import "@styles/common/index.sass";
import { user_is_on_mobile } from "@src/common/util/dom_extensions";

export default class PeekCarousel extends HTMLElement {
    set loop(val: string) {
        this.setAttribute("loop", val === "true" ? "true" : "false");
    }
    get loop() {
        return this.getAttribute("loop") || "false";
    }
    set pagination(val: string) {
        this.setAttribute("pagination", val === "true" ? "true" : "false");
    }
    get pagination() {
        return this.getAttribute("pagination") || "false";
    }
    set show_buttons(val: string) {
        this.setAttribute("show_buttons", val === "true" ? "true" : "false");
        this.setAttribute("show-buttons", val === "true" ? "true" : "false");
    }
    get show_buttons() {
        return this.getAttribute("show-buttons") || this.getAttribute("show_buttons") || "false";
    }
    set items_in_view(val: string) {
        this.setAttribute("items_in_view", parseInt(val || "2").toString());
        this.setAttribute("items-in-view", parseInt(val || "2").toString());
    }
    get items_in_view() {
        return this.getAttribute("items-in-view") || this.getAttribute("items_in_view") || "2";
    }
    set items_in_view_mobile(val: string) {
        this.setAttribute("items-in-view-mobile", parseInt(val || "1").toString());
    }
    get items_in_view_mobile() {
        return this.getAttribute("items-in-view-mobile") || "1";
    }
    set space_between(val: string) {
        this.setAttribute("space_between", parseInt(val || "0").toString());
        this.setAttribute("space-between", parseInt(val || "0").toString());
    }
    get space_between() {
        return this.getAttribute("space-between") || this.getAttribute("space_between") || "0";
    }
    get initial_slide() {
        return parseInt(this.getAttribute("initial-slide") || "0");
    }
    get getSwiper() {
        return this.swiper;
    }
    get isManual(): boolean {
        return this.hasAttribute("manual");
    }

    swiper: Swiper;
    
    constructor() {
        super();
        // This is to avoid carousel collisions
        let random_id = generateString(16);
        this.id = "my_swiper_" + random_id;
        this.classList.add(style_base + "swiper");
        this.classList.add(style_base + "block");
        this.classList.add(style_base + "relative");
        this.classList.add("afw-carousel-peek");
    }

    connectedCallback() {
        if (this.isManual) {
            return;
        }
        this.manualInit();
    }

    manualInit() {
        let wrapper = document.createElement("div");
        wrapper.classList.add("swiper-wrapper");
        // Get all children
        let slides = this.children;
        while (slides.length > 0) {
            // Move them out into the wrapper
            let slide_content = slides[0];
            let slide_wrapper = document.createElement("div");
            slide_wrapper.classList.add("swiper-slide");
            slide_wrapper.append(slide_content);
            wrapper.append(slide_wrapper);
        }
        this.append(wrapper);
        // Create a swiper config object 
        let config: SwiperOptions = {
            modules: [],
            loop: this.loop === "true",
            slidesPerView: user_is_on_mobile ? parseInt(this.items_in_view) : parseInt(this.items_in_view_mobile),
            spaceBetween: parseInt(this.space_between),
            centeredSlides: true,
            initialSlide: this.initial_slide
        }
        config.modules.push(Manipulation);
        // Add the buttons if required
        if (this.show_buttons === "true") {
            let left_button  = document.createElement("div");
            let right_button = document.createElement("div");
            left_button .classList.add("swiper-button-prev");
            right_button.classList.add("swiper-button-next");
            this.append(left_button);
            this.append(right_button);
            config.modules.push(Navigation);
            config.navigation = {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            }
        }
        // Add pagination div if required
        if (this.pagination === "true") {
            let pagination = document.createElement("div");
            pagination.classList.add("swiper-pagination");
            this.append(pagination);
            config.modules.push(Pagination);
            config.pagination = {
                el: ".swiper-pagination",
                clickable: true,
            }
        }
        // Initialize the swiper
        let swiper = new Swiper("#" + this.id, config);
        this.swiper = swiper;
    }
}

customElements.define("afw-carousel-peek",  PeekCarousel);