import ModalProviderAPI from "@src/common/providers/modal_provider";
import { user_is_on_mobile, user_is_on_opera, user_is_on_safari } from "@src/common/util/dom_extensions";
import "@styles/common/templates/modals/step_modal_mobile.sass";
import Portal from "../portal";
import AbstractModal from "./abstract_modal";

export default class StepModalMobile extends AbstractModal {    
    constructor() {
        super();
        this.classList.add("afw-modal", "afw-step-modal-mobile");
        if (user_is_on_safari()) {
            this.classList.add("afw-sheet-modal-safari");
        }
        if (user_is_on_opera()) {
            this.classList.add("afw-sheet-modal-opera");
        }
        // Standard javascript bullshit
        // Make the class's function remember the instance they belong to
        this.show = this.show.bind(this);
        this.hide = this.hide.bind(this);
        this.nextStep = this.nextStep.bind(this);
        this.listenToTouchDrag = this.listenToTouchDrag.bind(this);
        this.handleTouchDrag = this.handleTouchDrag.bind(this);
        this.listenMove = this.listenMove.bind(this);
        this.listenStart = this.listenStart.bind(this);
        this.listenEnd = this.listenEnd.bind(this);
        this.createBody();
    }

    connectedCallback() {
        this.listenToTouchDrag();
        // Set the base offset to be just before the second element
        setTimeout(() => {
            let secondElement = this.querySelector(".afw-modal-body").children[0] as HTMLElement;
            this.baseOffset = this.clientHeight - (secondElement.offsetTop + secondElement.offsetHeight + this.paddingBottom + 20 /* pull tab height */);
            this.currentYOffset = this.baseOffset;
        }, 0);
    }

    disconnectedCallback() {
        this.stopListeningToTouchDrag();
    }

    createBody() {
        let footer = document.createElement("div");
        footer.classList.add("afw-modal-footer"); // Actually the top part
        this.append(footer);
        let mbody = document.createElement("div");
        mbody.classList.add("afw-modal-body");
        this.append(mbody);
    }

    nextStep() {
        this.baseOffset = 0;
    }

    listenToTouchDrag() {
        let portal = Portal.getInstance();
        // Listen to touch move events on portal element
        portal.addEventListener("touchmove", this.listenMove);
        portal.addEventListener("touchstart", this.listenStart);
        portal.addEventListener("touchend", this.listenEnd);
    }

    stopListeningToTouchDrag() {
        let portal = Portal.getInstance();
        portal.removeEventListener("touchmove", this.listenMove);
        portal.removeEventListener("touchstart", this.listenStart);
        portal.removeEventListener("touchend", this.listenEnd);
    }

    /* 
        These are so that we can disconnect the event listeners since they
        are actual functions instead of anonymous ones
    */
    listenMove  (event: TouchEvent) {this.handleTouchDrag(event)};
    listenStart (event: TouchEvent) {if (!this.isDragging)  this.startDragging(event);}
    listenEnd   (event: TouchEvent) {if (this.isDragging)   this.stopDragging(event)}

    isDragging: boolean;
    dragStart: Touch;
    dragStartTimestamp: number;
    lastYFinger: number;
    lastXFinger: number;
    private hCurrentYOffset: number;
    baseOffset: number;
    
    public set currentYOffset(v : number) {
        this.hCurrentYOffset = v;
        let transform = `translateY(${v}px)`;
        if (v == 0) {
            transform = "";
        }
        this.dataset.transform = transform;
        this.style.transform = transform;
    }
    public get currentYOffset() : number {
        return this.hCurrentYOffset;
    }
    
    public get paddingBottom() : number {
        return parseInt(window.getComputedStyle(this).paddingBottom.replace("px", ""));
    }
    public get debug() : boolean {
        return this.hasAttribute("debug");
    }

    closeSelf(): void {
        this.baseOffset = 1000;
        this.currentYOffset = this.baseOffset;
        if (this.modalId) {
            ModalProviderAPI.getInstance().closeModal(this.modalId);
        }
        if (this.debug) {
            let context = Portal.getInstance().canvasOverlayContext;
            context.clearRect(0, 0, window.visualViewport.width, window.visualViewport.height);
        }
    }

    handleTouchDrag(event: TouchEvent) {
        if (!this.isDragging) {
            return;
        }
        let fingerX = event.changedTouches[0].clientX;
        let fingerY = event.changedTouches[0].clientY;
        if (this.debug) {
            let context = Portal.getInstance().canvasOverlayContext;
            context.beginPath();
            context.moveTo(this.lastXFinger, this.lastYFinger);
            context.lineTo(fingerX, fingerY);
            context.strokeStyle = "#ff0000";
            context.stroke();
        }
        let deltaY = fingerY - this.lastYFinger;
        let newOffset = this.currentYOffset + deltaY;
        // Check if the user is pulling it too far
        if (newOffset < 0) {
            return;
        }
        this.style.transitionDuration = "0ms";
        this.lastYFinger = fingerY;
        this.lastXFinger = fingerX;
        this.currentYOffset = newOffset;
    }

    startDragging(event: TouchEvent) {
        // Check if the user started dragging inside the div
        let windowY = window.visualViewport.height;
        let fingerX = event.changedTouches[0].clientX;
        let fingerY = event.changedTouches[0].clientY;
        let divHeight = this.clientHeight; // Size of the modal
        let divY = windowY - divHeight + (this.currentYOffset || 0);
        let hitboxYStart = divY;
        let firstElement = this.querySelector(".afw-modal-body").children[0] as HTMLElement;
        let hitboxYEnd = divY + 40 + firstElement.clientHeight;
        if (this.debug) {
            let context = Portal.getInstance().canvasOverlayContext;
            context.beginPath();
            context.strokeStyle = "#000000";
            context.moveTo(0, hitboxYStart);
            context.lineTo(window.visualViewport.width, hitboxYStart);
            context.moveTo(0, hitboxYEnd);
            context.lineTo(window.visualViewport.width, hitboxYEnd);
            context.stroke();
            context.beginPath();
            context.strokeStyle = "#ffffff";
            context.strokeText(hitboxYStart + "", 0, hitboxYStart);
            context.strokeText(hitboxYEnd + "", 0, hitboxYEnd);
            context.stroke();
            context.beginPath();
            context.strokeStyle = "#0000ff";
            context.strokeRect(fingerX - 10, fingerY - 10, 20, 20)
            context.stroke();
        }
        if (fingerY + 10 < hitboxYStart || hitboxYEnd < fingerY - 10 ) {
            // This means the user touched above the modal and under the little
            // pull tab at the top of the modal
            return;
        }
        this.dragStart = event.touches[0];
        this.dragStartTimestamp = event.timeStamp;
        this.isDragging = true;
        this.lastYFinger = event.touches[0].clientY;
        if (this.currentYOffset === undefined) {
            this.currentYOffset = 0;
        }
    }

    stopDragging(event: TouchEvent) {
        this.isDragging = false;
        this.style.transitionDuration = "";
        if (this.debug) {
            let context = Portal.getInstance().canvasOverlayContext;
            context.clearRect(0, 0, window.visualViewport.width, window.visualViewport.height);
        }
        // Check intent
        let intent = this.interpretIntent(event);
        if (intent === 1) {
            this.nextStep();
        }
        if (intent === -1) {
            this.closeSelf();
        }
        // If the intent was none then reset the current offset
        this.currentYOffset = this.baseOffset;
    }

    interpretIntent(event: TouchEvent) {
        let viewportHeight = window.visualViewport.height;
        let start = this.dragStart;
        let startTimestamp = this.dragStartTimestamp;
        let end   = event.changedTouches[0];
        let endTimestamp = event.timeStamp;
        // If the user ended their drag very close to the top 10% of the screen
        // then they must have meant to swipe up, this avoids all extra 
        // calculations
        if (end.clientY < viewportHeight * .1) {
            return 1;
        }
        // If the user ended at the bottom of the screen then the opposite 
        // should be returned
        if (end.clientY > viewportHeight - viewportHeight * .1) {
            return -1;
        }
        let delta = end.clientY - start.clientY;
        let deltaTime = endTimestamp - startTimestamp;
        // If the user tapped it, they must want to expand it!
        if (10 > delta && delta > -10) {
            return 1;
        }
        // Basically, we try to predict what the delta distance would be if
        // the user dragged for 1 second instead.
        // It's the cross-multiplication rule:
        //  a/b = c/d 
        //      where c = delta, d = deltaTime, b = 1000 (1 sec in ms) and a = prediction
        //      so we do a = (bc)/d and replace with
        let predict = (1000 * delta) / deltaTime;
        // This will tell us what the distance the user would probably swipe
        // over a second, if that is more than half of the user's viewport then
        // he must have wanted to swipe up
        //this.textContent = predict + "";
        if (predict < -(viewportHeight >> 1 /* Fast half division */)) {
            // Swipe up
            return 1;
        }
        // Let's be a little more generous with the swipe down since there 
        // could be less space for the user to do so
        if (predict > (viewportHeight >> 2 /* Fast quad division */)) {
            // Swipe down
            return -1;
        }
        return 0;
    }
}

customElements.define("afw-step-modal-mobile", StepModalMobile);