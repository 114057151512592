// Copied from myself https://github.com/onelikeandidie/onelikeandidie.github.io/blob/master/js/fake_jquery.module.js
// Myself being Pedro btw
// JQuery is bloat, so just made my own function
/**
 * Returns the element selected by the selector
 * 
 * If you want to select a class don't forget to check if the return was a list
 * of elements since classes can have multiple elements linked to them.
 * 
 * @param {string} selector Selector, looks like the css selectors
 * @param {T} element Optional argument, if passed will search inside 
 *  element instead of the document root
 * @returns {T | NodeListOf<T> | undefined}
 */
export default <T extends HTMLElement>(selector: string, element?: T): T | NodeListOf<T> | undefined => {
    let result;
    if (element instanceof HTMLElement) {
        result = element.querySelectorAll<T>(selector);
    } else {
        result = document.querySelectorAll<T>(selector);
    }
    if (result.length === 0) {
        // Nothing found
        return;
    }
    if (result.length > 1) {
        // More than one found
        return result;
    }
    if (result.length == 1) {
        // One was found, return it
        return result[0];
    }
};

/**
 * Finds an element with the given selector inside the given element
 */
export let find = <T extends HTMLElement>(element: T, selector: string) => {
    return element.querySelector(selector);
}

/**
 * Finds all elements with the given selector inside the given element
 */
export let findAll = <T extends HTMLElement>(element: T, selector: string) => {
    return element.querySelectorAll(selector);
}

/**
 * It takes an element or a list of elements and moves them to a target element
 * @param {HTMLElement | NodeListOf<HTMLElement>} element - The element you want to move.
 * @param {HTMLElement} target - HTMLElement - The element that you want to move the element to.
 * @returns The element is being returned.
 */
export let move = (element: HTMLElement | NodeListOf<HTMLElement>, target: HTMLElement) => {
    if (element instanceof NodeList) {
        for (const ele in element) {
            target.append(ele);
        }
        return;
    }
    return target.append(element);
}
