/**
 * It replaces all spaces in a string with dashes
 */
export let to_kebab_case = (s: string) => {
    s = s.toLowerCase();
    let a = s;
    do {
        s = a;
        a = s.replace(" ", "-");
    } while (a != s)
    return s;
}

/**
 * @returns `true` if the string passed to it is a header tag
 */
export let is_header_tag = (v: string) => (/H\d/g).test(v);