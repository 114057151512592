import { user_is_on_mobile } from "@src/common/util/dom_extensions";
import "@styles/common/templates/modals/step_modal.sass";
import AbstractModal from "./abstract_modal";

export default class StepModal extends AbstractModal {
    constructor() {
        super();
        this.classList.add("afw-modal", "afw-step-modal");
        this.show = this.show.bind(this);
        this.hide = this.hide.bind(this);
        this.createBody();
    }

    createBody() {
        let footer = document.createElement("div");
        footer.classList.add("afw-modal-footer"); // Actually the top part
        this.append(footer);
        let mbody = document.createElement("div");
        mbody.classList.add("afw-modal-body");
        this.append(mbody);
    }

    nextStep() {

    }
}

customElements.define("afw-step-modal", StepModal);