import { user_is_on_mobile } from "@src/common/util/dom_extensions";
import "@styles/common/templates/shortcuts/to_top.sass";

/* The `ToTopBtn` class is a custom HTML element that is used to scroll the page back to the top */
export default class ToTopBtn extends HTMLElement {
    public get threshold() : number {
        return parseInt(this.getAttribute("threshold") || "0");
    }

    lastScrollY: number;

    constructor() {
        super();
        this.classList.add("afw-to-top-btn");
        if (user_is_on_mobile()) {
            this.classList.add("afw-mobile");
        }
        this.handleScroll = this.handleScroll.bind(this);
    }

    connectedCallback() {
        this.innerHTML = `
            <a>
                <span class="text">Back to Top</span>
            </a>
        `;

        document.addEventListener("scroll", this.handleScroll);

        this.querySelector("a").addEventListener("mouseup", () => {
            window.scrollTo(0, 0);
        });
    }

    handleScroll(_scrollEvent: Event) {
        let scrollY = window.scrollY;
        // Check if the user is scrolling up and the scroll is over the threshold
        if (scrollY > this.threshold && this.lastScrollY > scrollY) {
            this.classList.add("shown");
        } else {
            this.classList.remove("shown");
        }
        this.lastScrollY = scrollY;
     }
}

customElements.define("afw-to-top-btn", ToTopBtn);