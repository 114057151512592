// https://api.reviews.co.uk/product/review
// https://api.reviews.co.uk/product/review?store=auto-finesse-ltd&sku=AFA-BAG1;AFA-BAG1&page=2

import LoadMoreContainer, { LoadMoreCustomEvent } from "@src/common/templates/async_containers/load_more";
import "@styles/common/templates/async_containers/smart_slidy_carousel.sass";
import { generateString } from "@src/common/util/generators";
import SlidyCarousel from "../carousels/slidy_carousel";
import Swiper from "swiper";

/**
 */
export default class SmartSlidyCarousel extends HTMLElement {
    set src(val: string) {
        this.setAttribute("src", val || `/`);
    }
    get src() {
        return this.getAttribute("src") || `/`;
    }
    set itemsInView(val: number) {
        this.setAttribute("items-in-view", (val || 2).toFixed(0));
    }
    get itemsInView(): number {
        return parseInt(this.getAttribute("items-in-view") || "2");
    }
    get showButtons(): boolean {
        return this.hasAttribute("show-buttons");
    }
    get perPageKey() {
        return this.getAttribute("per-page-key") || "per_page";
    }
    get customLoader(): boolean {
        return this.hasAttribute("custom-loader");
    }
    get customSlideHandler(): boolean {
        return this.hasAttribute("custom-slide-handler");
    }

    constructor() {
        super();
        this.id = "smart_carousel_" + generateString(16);
        this.classList.add("afw-smart-carousel");

        this.load_handler = this.load_handler.bind(this);
        this.slide_handler = this.slide_handler.bind(this);
    }

    firstLoad: boolean;

    connectedCallback() {
        let items_in_view = this.itemsInView;

        let url = new URL(this.src);
        let query = url.searchParams;
        query.set(this.perPageKey, items_in_view + 1 + "");
        let src = url.protocol + "//" + url.hostname + url.pathname + "?" + query.toString();

        this.firstLoad = true;

        this.innerHTML = `
            <afw-load-more src="${src}" paginated no-btn run-onseen custom-loader>
                <afw-carousel-slidy class="afw-no-overflow" items_in_view="${items_in_view}" space_between="0" initial-slide="1" show-buttons="${new Boolean(this.showButtons).toString()}">
                </afw-carousel-slidy>
            </afw-load-more>
        `;

        let load_more = this.querySelector("afw-load-more") as LoadMoreContainer;
        let carousel = load_more.querySelector("afw-carousel-slidy") as SlidyCarousel;
        if (!this.customLoader) {
            load_more.addEventListener("customload", this.load_handler);
        }
        if (!this.customSlideHandler) {
            carousel.swiper.on("slideChange", this.slide_handler);
        }
    }

    async load_handler(event: CustomEvent<LoadMoreCustomEvent>) {
        let text = await event.detail.response.text();
        let ele = document.createElement("span");
        ele.outerHTML = text;
        let carousel = this.querySelector("afw-carousel-slidy") as SlidyCarousel;
        carousel.swiper.appendSlide(ele);
    }

    async slide_handler(swiper: Swiper) {
        if (this.firstLoad) return;
        let load_more = this.querySelector("afw-load-more") as LoadMoreContainer;
        let max = swiper.el.querySelectorAll(".swiper-slide").length;
        let current = swiper.activeIndex + 1;
        let per_view_opt = swiper.originalParams.slidesPerView;
        let per_view = typeof per_view_opt == "number" ? per_view_opt : 3;
        if ((max - current) < per_view) {
            load_more.load_more();
            console.log("Loaded more reviews");
        }
    }
}

customElements.define("afw-smart-slidy-carousel", SmartSlidyCarousel);