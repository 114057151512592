import "@styles/common/templates/tabbed_container.sass";
import { style_base } from "../common";
import { generateString } from "../util/generators";

/**
 * Creates a div with tabs 
 * 
 * You can also define what classes a tab button should have as well
 * as the tab content. Here is the attributes you can add to the tabbed 
 * container:
 * - btn-group-class    > CSS class for the div containing the tab buttons
 * - open-btn-class     > CSS class to be added to the tab buttons when open
 * - closed-btn-class   > CSS class to be added to the tab buttons when closed
 * - open-tab-class     > CSS class to be added when a tab's content is open
 * - closed-tab-class   > CSS class to be added when a tab's content is closed
 * 
 * Yes you can put multiple classes. Just space them out
 * 
 * @example ````html
 * <tabbed-container>
 *      <!-- Any children divs with 2 divs inside become tab<->content pairs -->
 *      <div>
 *          <div">
 *              tab button
 *          </div>
 *          <div>
 *              tab content
 *          </div>
 *      </div>
 *  </tabbed-container>
 * ````
 */
class TabbedContainer extends HTMLElement {
    static get observedAttributes() {
        return [
            "btn-group-class",
            "open-btn-class",
            "closed-btn-class",
            "open-tab-class",
            "closed-tab-class",
        ]
    }

    set btnGroupClass(val: string) {
        this.setAttribute("btn-group-class", val || "no-class-there-is-no-class-here");
    }
    get btnGroupClass() {
        return this.getAttribute("btn-group-class") || "no-class-there-is-no-class-here";
    }
    set openBtnClass(val: string) {
        this.setAttribute("open-btn-class", val || "open");
    }
    get openBtnClass() {
        return this.getAttribute("open-btn-class") || "open";
    }
    set openTabClass(val: string) {
        this.setAttribute("open-tab-class", val || "open");
    }
    get openTabClass() {
        return this.getAttribute("open-tab-class") || "open";
    }
    set closedBtnClass(val: string) {
        this.setAttribute("closed-btn-class", val || "closed");
    }
    get closedBtnClass() {
        return this.getAttribute("closed-btn-class") || "closed";
    }
    set closedTabClass(val: string) {
        this.setAttribute("closed-tab-class", val || "closed");
    }
    get closedTabClass() {
        return this.getAttribute("closed-tab-class") || "closed";
    }

    constructor() {
        super();
        // Make the open function remember the owner
        this.open = this.open.bind(this);
        // Add the necessary css classes and a unique id
        this.classList.add("afw-tabbed-container");
        this.dataset.tabbed_container_id = generateString(16);
        let tab_container = document.createElement("div");
        let tab_list = document.createElement("div");
        tab_list.classList.add(...this.btnGroupClass.split(" "));
        let tabs = this.children;
        let tab_count = 0;
        // Go through each div in the tabbed container
        while (tabs.length > 0) {
            const tab = tabs[0];
            let i = 0;
            while (tab.children.length > 0) {
                const tab_content = tab.children[0] as HTMLElement;
                switch (i) {
                    // If it's the first element, then it is the tab button
                    case 0:
                        tab_content.classList.add(style_base + "tab-button");
                        tab_content.dataset.tab_id = tab_count.toString();
                        tab_content.classList.add(...this.closedBtnClass.split(" "));
                        tab_list.append(tab_content);
                        break;
                    // The second element is the tab content
                    case 1:
                        tab_content.classList.add(style_base + "tab-content");
                        tab_content.classList.add(...this.closedTabClass.split(" "));
                        tab_container.append(tab_content);
                        break;
                    // Disregard any other divs in there
                    default:
                        tab_content.remove();
                        break;
                }
                i++;
            }
            tab_count++;
            tab.remove();
        }
        // Make the first tab open
        tab_list.children[0].classList.add(...this.openBtnClass.split(" "));
        tab_list.children[0].classList.remove(...this.closedBtnClass.split(" "));
        tab_container.children[0].classList.add(...this.openTabClass.split(" "));
        tab_container.children[0].classList.remove(...this.closedTabClass.split(" "));
        this.dataset.selected = "0";
        // Listen for clicks on the tab buttons
        for (let i = 0; i < tab_list.children.length; i++) {
            const tab_button = tab_list.children[i] as HTMLElement;
            tab_button.onclick = this.open;
        }
        this.append(tab_list);
        this.append(tab_container);
    }

    open(click_event: MouseEvent) {
        let tab_button = click_event.target as HTMLElement;
        // This is a failsafe for <a> tags inside the tab buttons
        // Stops this functions and skips to those's events
        if (tab_button instanceof HTMLAnchorElement) {
            return true;
        } else if (tab_button.parentElement instanceof HTMLAnchorElement) {
            return true;
        }
        // This is in case the mouse clicked an element inside the button!
        if (!tab_button.classList.contains(`.${style_base}tab-button`)) {
            tab_button = tab_button.closest(`.${style_base}tab-button`) as HTMLElement;
        }
        const tabbed_container = tab_button.closest(`.${style_base}tabbed-container`) as TabbedContainer;
        // Get the tab to open and the open tab
        let tab_id_to = parseInt(tab_button.dataset.tab_id || "-1");
        let tab_id_from = parseInt(tabbed_container.dataset.selected || "-1");
        // Check if any of the dataset gets failed and quit
        if (tab_id_to == -1 || tab_id_from == -1) {
            return false;
        }
        // Check if the tab is the same and quit if so
        if (tab_id_to == tab_id_from) {
            return false;
        }
        // Update the buttons and tabs
        let buttons = tabbed_container.querySelectorAll(`.${style_base}tab-button`);
        let tabs    = tabbed_container.querySelectorAll(`.${style_base}tab-content`);
        buttons[tab_id_from].classList.remove(...this.openBtnClass.split(" "));
        buttons[tab_id_from].classList   .add(...this.closedBtnClass.split(" "));
        buttons[tab_id_to]  .classList.remove(...this.closedBtnClass.split(" "));
        buttons[tab_id_to]  .classList   .add(...this.openBtnClass.split(" "));
        tabs[tab_id_from]   .classList.remove(...this.openTabClass.split(" "));
        tabs[tab_id_from]   .classList   .add(...this.closedTabClass.split(" "));
        tabs[tab_id_to]     .classList.remove(...this.closedTabClass.split(" "));
        tabs[tab_id_to]     .classList   .add(...this.openTabClass.split(" "));
        // Update the tabbed container
        tabbed_container.dataset.selected = tab_id_to.toString();
    }
}

customElements.define('afw-tabbed-container', TabbedContainer);

export default TabbedContainer;