import NotificationProviderAPI from "@src/common/providers/notification_provider";
import "@styles/common/templates/provider.sass";

export default class NotificationProviderAPIElement extends HTMLElement {
    constructor() {
        super();
        this.getInstance = this.getInstance.bind(this);
        this.classList.add("api-provider-ele");
    }

    public getInstance(): NotificationProviderAPI {
        return NotificationProviderAPI.getInstance();
    }
}

customElements.define("afw-notification-provider-api", NotificationProviderAPIElement);