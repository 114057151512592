import { to_kebab_case } from "./string_extensions";

/**
 * It takes an HTML element and wraps it in an anchor tag that links to the
 * element's id.
 * If the element does not have an ID, one is created
 * @param {HTMLElement} child - The child element to wrap in an anchor tag
 */
export let wrap_in_anchor_with_id = (child: HTMLElement) => {
    if (child.id == "") {
        child.id = to_kebab_case(child.textContent.substring(0, 16));
    }
    let old_html = child.innerHTML;
    // Create the anchor to give elements links
    let anchor_link = document.createElement("a");
    let origin = document.URL.substring(0, document.URL.indexOf("#"));
    anchor_link.href = origin + "#" + child.id;
    // Attach the header html to the anchor tag
    anchor_link.innerHTML = old_html;
    // Reassign the html
    child.innerHTML = anchor_link.outerHTML;
}

/**
 * It returns true if the user is on a mobile device.
 */
export let user_is_on_mobile = () => {
    let a = navigator.userAgent;
    return /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a)||/1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0,4));
}

/**
 * Returns true if the user is on safari
 */
export let user_is_on_safari = () => {
    let a = navigator.userAgent;
    let safariAgent = a.indexOf("Safari") > -1;
    let chromeAgent = a.indexOf("Chrome") > -1;
    // Since Chrome on IOs actually uses Safari in the back it reports as both
    if (chromeAgent && safariAgent) return false;
    return safariAgent;
}

export let user_is_on_opera = () => {
    let a = navigator.userAgent;
    let matches = a.match(/Opera|OPR|OPX\//); // Opera = Old Opera, OPR = New Opera, OPX = Opera GX
    let operaAgent = matches != null && matches.length > 0;
    return operaAgent;
}

// BISCUIT METHODS

/**
 * It sets a cookie
 * @param {string} name - The name of the biscuit.
 * @param {string} value - The value of the cookie.
 * @param {number} [expire_seconds] - The number of seconds until the cookie expires. If omitted, the
 * cookie becomes a session cookie.
 */
export let set_biscuit = (name: string, value: string, expire_seconds?: number) => {
    let expires = "";
    if (expire_seconds) {
        expires = "; max-age=" + expire_seconds;
    }
    document.cookie = name + "=" + (value || "")  + expires + "; path=/";
}

/**
 * Get the value of a biscuit by name.
 * @param {string} name - The name of the biscuit you want to get
 * @returns The value of the biscuit
 */
export let get_biscuit = (name: string) => {
    let key = name + "=";
    let biscuit_string = document.cookie.split(';');
    for(let i = 0; i < biscuit_string.length; i++) {
        let biscuit = biscuit_string[i];
        // Remove trailing whitespace
        biscuit = biscuit.trim();
        // Check if the key is still the same after trimming
        if (biscuit.indexOf(key) == 0) {
            return biscuit.substring(key.length, biscuit.length)
        };
    }
    return null;
}

/**
 * It erases a cookie.
 * @param {string} name - the name of the cookie you want to erase
 */
export let erase_biscuit = (name: string) => {   
    document.cookie = name +'=; path=/; max-age=0;';
}

/** Returns true if the element is a node list */
export let is_node_list = (obj: Element | NodeListOf<Element>) => {
    return obj instanceof NodeList;
}

/**
 * Sets up event listeners for mouseenter and mouseleave events
 * @param {Element} element Dom element
 * @param {function} enter Callback to enter hover
 * @param {function} leave Callback to exit hover
 */
 export let hover_element = (element: Element, enter: (e: MouseEvent | any) => void, leave: (e: MouseEvent | any) => void) => {
    element.addEventListener("mouseenter", enter);
    element.addEventListener("mouseleave", leave);
}

/**
 * Sets up event listeners for mouseenter and mouseleave events
 * @param {string} selector CSS Selector
 * @param {function} enter Callback to enter hover
 * @param {function} leave Callback to exit hover
 */
export let hover = (selector: string, enter: (e: MouseEvent | any) => void, leave: (e: MouseEvent | any) => void) => {
    let search = $(selector);
    if (Array.isArray(search)) {
        for (let i = 0; i < search.length; i++) {
            const element = search[i];
            hover_element(element, enter, leave);
        }
    } else {
        //@ts-ignore
        hover_element(search, enter, leave);
    }
}