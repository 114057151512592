import ModalProviderAPI from "@src/common/providers/modal_provider";

export default class AbstractModal extends HTMLElement {
    public get animationTimeout() : number {
        return parseInt(this.getAttribute("animation-timeout") || "300");
    }
    public get modalId() : string | false {
        return this.dataset.modalId || false;
    }

    show() {
        this.classList.add("shown");
    }
    
    hide() {
        this.classList.remove("shown");
    }

    closeSelf() {
        if (this.modalId) {
            ModalProviderAPI.getInstance().closeModal(this.modalId);
        }
    }
}