import "@styles/common/templates/async_containers/smart_galery.sass";
import Swiper from "swiper";
import SlidyCarousel from "../carousels/slidy_carousel";
import IMGSkeleton from "../skeletons/img_skeleton";

export default class SmartGalery extends HTMLElement {
    public get showButtons() : boolean {
        return this.hasAttribute("show-buttons");
    }
    public get loop() : boolean {
        return this.hasAttribute("loop");
    }
    public get noThumbImg() : boolean {
        return this.hasAttribute("no-thumb-img");
    }
    public get thumbClasses() : string[] {
        let thumbClass = this.getAttribute("thumb-class");
        if (thumbClass != null) {
            return thumbClass.split(" ");
        }
        return [];
    }
    public get thumbImgClasses() : string[] {
        let thumbClass = this.getAttribute("thumb-img-class");
        if (thumbClass != null) {
            return thumbClass.split(" ");
        }
        return [];
    }
    public get imgClasses() : string[] {
        let imgClass = this.getAttribute("img-class");
        if (imgClass != null) {
            return imgClass.split(" ");
        }
        return [];
    }

    items: GaleryItem[]
    /** Index of the latest loaded item */
    loaded: number;

    constructor() {
        super();
        this.classList.add("afw-smart-galery");
        if (this.hasAttribute("default-styles")) {
            this.classList.add("afw-smart-galery-ugly");
        }
        this.items = [];
        this.loaded = 0;
        this.handleSlideChange = this.handleSlideChange.bind(this);
        this.handleThumbClick = this.handleThumbClick.bind(this);
    }
    
    connectedCallback() {
        // Process all "images" in the galery
        let children = this.children;
        for (let i = 0; i < children.length; i++) {
            const child = children[i] as HTMLElement;
            if (child.tagName === "I") {
                let classes = child.classList.value != "" ? child.classList.value.split(" ") : [];
                let galeryItem: GaleryItem = {
                    name: child.getAttribute("name"),
                    image_src: child.getAttribute("image"),
                    thumb_src: child.hasAttribute("thumb") ? child.getAttribute("thumb") : "",
                    extra_classes: classes,
                }
                this.items.push(galeryItem);
            }
        }
        this.innerHTML = "";
        // Create the carousel to store all the loaded image elements
        let ghostCarousel = document.createElement("div");
        ghostCarousel.innerHTML = `<afw-carousel-slidy class="c afw-smart-galery-carousel" items-in-view="1" loop="${new Boolean(this.loop).toString()}" show-buttons="${new Boolean(this.showButtons).toString()}"></afw-carousel-slidy>`
        let actualCarousel = ghostCarousel.children[0] as SlidyCarousel;
        this.append(actualCarousel);
        // Listen to carousel slide changes
        setTimeout(() => {
            actualCarousel.swiper.on("slideChange", this.handleSlideChange);
        }, 0);
        // Create the element to store all the thumbnails
        let thumbContainer = document.createElement("div");
        thumbContainer.classList.add("afw-smart-thumbs");
        let thumbElements = [];
        // Load thumbnails
        for (let i = 0; i < this.items.length; i++) {
            const item = this.items[i];
            let div = document.createElement("div");
            div.classList.add("afw-smart-thumb");
            div.setAttribute("name", item.name);
            div.setAttribute("index", i + "");
            if (this.thumbClasses.length > 0) {
                div.classList.add(...this.thumbClasses);
            }
            if (!this.noThumbImg) {
                let img = new IMGSkeleton();
                img.src = item.thumb_src;
                if (this.thumbImgClasses.length > 0) {
                    img.classList.add(...this.thumbImgClasses);
                }
                div.append(img);
            }
            thumbContainer.append(div);
            thumbElements.push(div);
        }
        // Select the first thumb
        thumbElements[0].classList.add("selected");
        this.append(thumbContainer);
        // Listen to clicks on the thumbnails
        for (let i = 0; i < thumbElements.length; i++) {
            const thumb = thumbElements[i];
            thumb.addEventListener("mousedown", this.handleThumbClick);
        }
        // Load the first images
        setTimeout(() => {
            for (let i = 0; i < this.items.length; i++) {
                if (i > 1) { // Don't load more than the 1st and second images
                    continue;
                }
                let [div, _img] = this.loadImage(i);
                actualCarousel.swiper.appendSlide(div);
            }
        }, 0);
    }

    disconnectedCallback() {
        
    }

    handleThumbClick(mouseEvent: MouseEvent) {
        let target = (mouseEvent.target as HTMLElement).closest(".afw-smart-thumb") as HTMLElement;
        let slideIndex = parseInt(target.getAttribute("index"));
        this.select(slideIndex);
    }

    handleSlideChange(swiper: Swiper) {
        let activeIndex = swiper.activeIndex;
        // Add the selected attribute to the thumb
        let thumbs = this.querySelectorAll(".afw-smart-thumb");
        for (let i = 0; i < thumbs.length; i++) {
            const thumb = thumbs[i] as HTMLElement;
            if (thumb.classList.contains("selected")) {
                thumb.classList.remove("selected");
            }
        }
        thumbs[activeIndex].classList.add("selected");
        // Check if it is the last slide
        let isLastSlide = activeIndex == swiper.slides.length - 1;
        if (!isLastSlide) {
            return;
        }
        // Load the next image
        let newSlide = activeIndex + 1;
        this.loadItem(newSlide);
    }

    loadItem(index: number) {
        if (index > this.items.length - 1) {
            return;
        }
        let carousel = this.querySelector("afw-carousel-slidy") as SlidyCarousel;
        carousel.swiper.appendSlide(this.loadImage(index)[0]);
    }

    loadImage(index: number) {
        let item = this.items[index];
        let div = document.createElement("div");
        div.classList.add("swiper-slide");
        let img = new IMGSkeleton();
        img.classList.add("afw-smart-galery-img");
        img.setAttribute("name", item.name);
        img.setAttribute("index", index + "");
        img.setAttribute("no-back", "");
        let classes = [...item.extra_classes, ...this.imgClasses];
        if (classes.length > 0) {
            img.setAttribute("img-class", classes.join(" "));
        }
        img.src = item.image_src;
        div.append(img);
        this.loaded++;
        return [div, img];
    }

    ensureLoaded(targetIndex: number) {
        if (this.loaded >= targetIndex) {
            // This means it's already loaded
            return true;
        }
        let carousel = this.querySelector("afw-carousel-slidy") as SlidyCarousel;
        for (let i = this.loaded; i <= targetIndex; i++) {
            carousel.swiper.appendSlide(this.loadImage(i)[0]);
        }
    }

    select(index: number|string) {
        if (typeof index == "number") {
            return this.selectByIndex(index);
        }
        if (typeof index == "string") {
            if (index.match(/.(png|jpe?g|webp|gif)/g)) {
                return this.selectBySrc(index);
            }
            return this.selectByName(index);
        }
        return false;
    }

    selectByIndex(index: number) {
        // Check if this one is loaded
        this.ensureLoaded(index);
        setTimeout(() => {
            let carousel = this.querySelector("afw-carousel-slidy") as SlidyCarousel;
            carousel.swiper.slideTo(index);
        }, 0);
    }

    selectByName(name: string) {

    }

    selectBySrc(src: string) {

    }
}

type GaleryItem = {
    name: string,
    image_src: string,
    thumb_src: string,
    extra_classes: string[],
}

customElements.define("afw-smart-galery", SmartGalery);