import "@src/common";
import AbstractModal from "../templates/modals/abstract_modal";
import BasicModal from "../templates/modals/basic_modal";
import SheetModal from "../templates/modals/sheet_modal";
import StepModal from "../templates/modals/step_modal";
import StepModalMobile from "../templates/modals/step_modal_mobile";
import Portal from "../templates/portal";
import { user_is_on_mobile } from "../util/dom_extensions";
import { generateString } from "../util/generators";

/**
 * Handles showing and closing modals
 */
export default class ModalProviderAPI {
    // Singleton static stuff
    static provider: ModalProviderAPI

    static getInstance(): ModalProviderAPI {
        if (ModalProviderAPI.provider == undefined) {
            ModalProviderAPI.provider = new ModalProviderAPI();
        }
        return ModalProviderAPI.provider;
    }
    
    /** List of modal trackers */
    modalList: ModalTracker[];

    private constructor() {
        this.modalList = [];
        // Add event listener for clicking off a modal
        let portal = Portal.getInstance();
        portal.querySelector(".pointer-catcher").addEventListener("mouseup", () => {
            ModalProviderAPI.getInstance().closeTopmostModal();
        });
    }

    getModal(id: string) {
        return document.querySelector("#modal_" + id);
    }

    showModal<T extends AbstractModal>(options?: ShowModalOptions, parentId?: string): T | false {
        // Check if any elements were passed
        if (options.elements === undefined || !Array.isArray(options.elements)) {
            throw new Error("HTML Elements not passed into modal options!");
        }
        // Add the default options
        options = Object.assign(this.defaultModalOptions(), options);
        // Create a modal accordingly
        let modal = this.createModal(options) as T;
        if (!modal) {
            return false;
        }
        let id = generateString(16);
        modal.id = "modal_" + id;
        modal.dataset.modalId = id;
        // Add it to the modal list
        this.modalList.push({
            id,
            children: []
        });
        // Add it to the parent if provided and exists
        if (parentId !== undefined && typeof parentId === "string") {
            let listIndex = this.modalList.findIndex((m) => m.id == parentId);
            this.modalList[listIndex].children.push(id);
            // And also tell the parent css that there is another modal shown over it
            let parent = this.getModal(parentId);
            parent.classList.add("faded");
        }
        // Read all the options
        this.attachOptions(modal, options);
        // Add the elements
        modal.querySelector(".afw-modal-body").append(...options.elements);
        // Append it to the overlay
        let portal = Portal.getInstance();
        let attach_point = this.getAttachPoint(options);
        attach_point.append(modal);
        // This is so the DOM css updates before toggling css classes and
        // therefore playing animations
        setTimeout(() => {
            portal.show();
            modal.show();
        }, 0);
        return modal;
    }

    defaultModalOptions(): ShowModalOptions {
        return {
            cancelable: false, 
            footerOptions: [{
                type: "close",
            }],
            showFooter: true
        };
    }

    createModal(options: ShowModalOptions): AbstractModal  {
        /*  Special Types
            For example modals for mobile should be displayed differently
         */
        if (options.special != undefined) {
            switch (options.special) {
                case "sheet":
                    if (user_is_on_mobile()) {
                        return new SheetModal();
                    }
                    // On desktop, users just get the basic modal
                    return new BasicModal();
                case "step":
                    if (user_is_on_mobile()) {
                        return new StepModalMobile();
                    }
                    return new StepModal();
                case "basic":
                    return new BasicModal();
            }
        }
        return new BasicModal();
    }

    getAttachPoint(options: ShowModalOptions) {
        const portal = Portal.getInstance();
        if (options.special != undefined) {
            if (!user_is_on_mobile()) {
                return portal.modalContainer;
            }
            switch (options.special) {
                case "step":
                case "sheet":
                    return portal.sheetModalContainer;
            }
        }
        return portal.modalContainer;
    }

    attachOptions(modal: AbstractModal, options: ShowModalOptions) {
        /*  Cancelable
            If a modal is cancelable then any click outside of the modal box
            will close it and fire a cancel event.
         */
        if (options.cancelable === undefined || options.cancelable) {
            let button = document.createElement("a");
            button.classList.add("afw-modal-close-btn");
            button.addEventListener("mouseup", (_event) => {
                ModalProviderAPI.getInstance().closeModal(modal.dataset.modalId);
            });
            modal.append(button);
            modal.dispatchEvent(new CustomEvent("modalcanceled"));
            modal.dataset.closable = "true";
        }
        /*  Footer Options
            Default is to have a close button. If buttons are provided, check 
            the type and add them to the footer.
         */
        if (options.footerOptions !== undefined && Array.isArray(options.footerOptions)) {
            let footer = modal.querySelector(".afw-modal-footer");
            for (const option of options.footerOptions) {
                let button: HTMLElement = document.createElement("a");
                button.classList.add("afw-modal-footer-btn");
                switch (option.type) {
                    case "close":
                        button.classList.add("modal-close");
                        button.textContent = option.data || "Close";
                        button.addEventListener("mouseup", (_event) => {
                            modal.dispatchEvent(new CustomEvent("close"));
                            ModalProviderAPI.getInstance().closeModal(modal.dataset.modalId);
                        });
                        break;
                    case "ok":
                        button.classList.add("modal-ok");
                        button.textContent = option.data || "Ok";
                        button.addEventListener("mouseup", (_event) => {
                            modal.dispatchEvent(new CustomEvent("ok"));
                            ModalProviderAPI.getInstance().closeModal(modal.dataset.modalId);
                        });
                        break;
                    case "next":
                        button.classList.add("modal-next");
                        button.textContent = option.data || "";
                        button.addEventListener("mouseup", (_event) => {
                            modal.dispatchEvent(new CustomEvent("next"));
                            let stepModal = ModalProviderAPI.getInstance().getModal(modal.dataset.modalId) as (StepModal | StepModalMobile);
                            stepModal.nextStep();
                        });
                        break;
                    case "custom":
                        button = option.element;
                        button.dataset.buttonActionId = option.data;
                        button.addEventListener("mouseup", (_event) => {
                            modal.dispatchEvent(
                                new CustomEvent<ModalButtonCustomEvent>("custombtn", {
                                    detail: option.data
                                }
                            ));
                        });
                        break;
                }
                footer.append(button);
            }
        }
        /*  Show Footer
            Defaults to showing a footer, hides the footer element if false
         */
        if (options.showFooter !== undefined && options.showFooter === false) {
            let footer =modal.querySelector(".afw-modal-footer") as HTMLElement;
            if (footer) {
                footer.hidden = true;
            }
        }
        /*  Class Suffix
            There's no default suffix. Any option will add to the modal's class.
            There is some default styles however (which should be pretty 
            self-explainatory):
                - Small         - Tall          - Wide
         */
        if (options.classSuffix !== undefined && typeof options.classSuffix == "string") {
            modal.classList.add(...options.classSuffix.split(" "));
        }
        /*  Debug
            Adds the debug attribute the to modal, serves no purpose unless the
            modal implements the functionality
         */
        if (options.debug) {
            modal.setAttribute("debug", options.debug + "");
        }
    }

    closeModal(modal: AbstractModal | string) {
        let modalId: string;
        if (modal instanceof AbstractModal) {
            modalId = modal.modalId || "what happened? this shouldn't happen";
        } else {
            modalId = modal;
        }
        let listIndex = this.modalList.findIndex((m) => m.id == modalId);
        let modalTracker = this.modalList[listIndex];
        if (listIndex == -1) {
            console.error("Modal", modalId, " not found");
            return;
        }
        // Check if the modal has children
        if (modalTracker.children.length > 0) {
            this.closeChildren(modalTracker);
        }
        // Check if the modal has any parents
        this.updateParents(modalTracker);
        // Close the modal and remove it from the list
        let modalEle = document.querySelector("#modal_" + modalId) as BasicModal;
        if (modalEle) {
            modalEle.hide();
            modalEle.dispatchEvent(new CustomEvent("modalclosed"));
            // Remove element after some time for animations to play out
            setTimeout(() => modalEle.remove(), modalEle.animationTimeout);
        }
        // P.S.: We need to check again what the index of the modal is since it
        //      could have changed after removing children
        listIndex = this.modalList.findIndex((m) => m.id == modalId);
        this.modalList.splice(listIndex, 1);
        // If the modal list is empty then all modals have been completed
        // henceforth we should disable the overlay
        if (this.modalList.length == 0) {
            Portal.getInstance().hide();
        }
    }

    getTopmostModalId() {
        // Get the last modal added to the portal
        // P.S.: Should be the first element found since we prepend it to the
        //      portal overlay
        let portal = Portal.getInstance();
        let latestModal = portal.querySelector(".afw-modal") as BasicModal;
        if (latestModal === undefined) {
            return false;
        }
        if (latestModal.dataset.closable !== "true") {
            return false;
        }
        return latestModal.dataset.modalId;
    }

    closeTopmostModal() {
        let id = this.getTopmostModalId();
        if (!id) {
            console.error("No cancelable modals being shown");
            return;
        }
        this.closeModal(id);
    }

    private closeChildren(modalTracker: ModalTracker) {
        // Close all child modals
        for (const child of modalTracker.children) {
            this.closeModal(child);
        }
    }

    private updateParents(modalTracker: ModalTracker) {
        let listIndexes = this.modalList
            // Find parents
            .map((m, i) => m.children.includes(modalTracker.id) ? i : -1)
            // Filter non parents
            .filter(i => i > -1);
        // Remove child from parent
        for (const parentIndex of listIndexes) {
            let children = this.modalList[parentIndex].children;
            // Find index of itself in the children
            let childIndex = children.indexOf(modalTracker.id);
            // Remove from the child list
            this.modalList[parentIndex].children.splice(childIndex, 1);
            // And also tell the parent css that there is no longer another modal shown over it
            let parent = this.getModal(this.modalList[parentIndex].id);
            parent.classList.remove("faded");
        }
    }
}

type ShowModalOptions = {
    cancelable?: boolean,
    footerOptions?: ModalButton[]
    showFooter?: boolean,
    classSuffix?: "small" | "tall" | "wide" | "fill" | string,
    special?: "basic" | "sheet" | "step",
    elements?: HTMLDivElement[],
    debug?: boolean,
}

type ModalButton = {
    type: "close" | "ok" | "next" | "custom",
    element?: HTMLElement
    /** Added to the event data when this button is pressed */
    data?: any
}

export interface ModalButtonCustomEvent {
    customId: any
}


type ModalTracker = {
    id: string;
    children: string[];
}