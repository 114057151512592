export let timeBetween = (start: Date, end: Date) => {
    const second = 1000,
        minute = second * 60,
        hour = minute * 60,
        day = hour * 24;
    let timeStart = start.getTime();
    let timeEnd = end.getTime();
    let delta = timeEnd - timeStart;
    let days = Math.floor(delta / day);
    let hours = Math.floor(delta % day / hour);
    let minutes = Math.floor(delta % hour / minute);
    let seconds = Math.floor(delta % minute / second);
    return {
        days,
        hours,
        minutes,
        seconds
    }
}