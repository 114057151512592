import { style_base } from "@src/common";
import { user_is_on_mobile } from "../util/dom_extensions";

/**
 * Wrapper for displaying 2 diffrent elements depending on the hover state.
 * 
 * @example ```html
 *  <stk-hover shown-class="shown" hidden-class="hidden">
 *      <h1>Hover 1</h1>
 *      <h1>Hover 2</h1>
 *  </stk-hover>
 * ```
 */
export default class HoverMan extends HTMLElement {
    static get observedAttributes() {
        return [
            "delay",
            "shown-class",
            "hidden-class"
        ]
    }

    set delay(val: string) {
        this.setAttribute("delay", parseFloat(val || "0").toFixed(2));
    }
    get delay() {
        return this.getAttribute("delay") || "0";
    }
    set shownClass(val: string) {
        this.setAttribute("shown-class", val);
    }
    get shownClass() {
        return this.getAttribute("shown-class") || "shown";
    }
    set hiddenClass(val: string) {
        this.setAttribute("hidden-class", val);
    }
    get hiddenClass() {
        return this.getAttribute("hidden-class") || "hidden";
    }

    constructor() {
        super();
        this.onMouseEnter = this.onMouseEnter.bind(this);
        this.onMouseLeave = this.onMouseLeave.bind(this);
        this.classList.add("afw-hover");
        if (!user_is_on_mobile()) {
            this.addEventListener("mouseenter", this.onMouseEnter);
            this.addEventListener("mouseleave", this.onMouseLeave);
        }
        // Initial state is "onMouseLeave"
        this.onMouseLeave();
        // Delete any children over the max amount of 2
        for (let i = 2; i < this.children.length; i++) {
            const ele = this.children[i];
            ele.remove();
        }
    }

    onMouseEnter() {
        let shown_classes   = this.shownClass.split(" ");
        let hidden_classes  = this.hiddenClass.split(" ");
        this.children[0].classList.remove(...shown_classes);
        this.children[0].classList.add   (...hidden_classes);
        this.children[1].classList.remove(...hidden_classes);
        this.children[1].classList.add   (...shown_classes);
    }

    onMouseLeave() {
        let shown_classes   = this.shownClass.split(" ");
        let hidden_classes  = this.hiddenClass.split(" ");
        this.children[0].classList.remove(...hidden_classes);
        this.children[0].classList.add   (...shown_classes);
        this.children[1].classList.remove(...shown_classes);
        this.children[1].classList.add   (...hidden_classes);
    }
}

customElements.define("afw-hover", HoverMan);