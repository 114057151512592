import { style_base } from "@src/common";
import "@styles/common/templates/expandables/dropdown.sass";

export default class Dropdown extends HTMLElement{
    static get observedAttributes() {
        return [
            "show-class",
            "shown",
            "y-offset"
        ]
    }

    set showClass(val: string) {
        this.setAttribute("show-class", val || `${style_base}dropdown-show`);
    }
    get showClass() {
        return this.getAttribute("show-class") || `${style_base}dropdown-show`;
    }
    set shown(val: boolean) {
        this.setAttribute("shown", val ? "true" : "false");
    }
    get shown() {
        return this.getAttribute("shown") == "true";
    }
    set yOffset(val: string) {
        this.setAttribute("y-offset", val || "0");
    }
    get yOffset() {
        return this.getAttribute("y-offset");
    }

    constructor() {
        super();
        this.classList.add(`${style_base}dropdown`);
        this.update_offset = this.update_offset.bind(this);
        this.update_shown  = this.update_shown.bind(this);
    }

    attributeChangedCallback(name: string, oldValue: string, newValue: string) {
        switch (name) {
            case "shown":
                this.update_shown();
                break;
            case "y-offset":
                this.update_offset();
                break;
        
            default:
                break;
        }
    }

    update_shown() {
        if (this.shown) {
            this.classList.add(this.showClass);
        } else {
            this.classList.remove(this.showClass);
        }
    }
    
    update_offset() {
        this.style.top = this.yOffset;
    }
}

customElements.define("afw-dropdown", Dropdown);