import "@src/common";
import { style_base } from "@src/common";
import NotificationToast from "@src/common/templates/notifications/toast";
import NotificationBanner from "../templates/notifications/banner";

/**
 * Handles Toasts, Banners and Alerts
 */
export default class NotificationProviderAPI {
    // Singleton static stuff
    static provider: NotificationProviderAPI

    static getInstance(): NotificationProviderAPI {
        if (NotificationProviderAPI.provider == undefined) {
            NotificationProviderAPI.provider = new NotificationProviderAPI();
        }
        return NotificationProviderAPI.provider;
    }

    // Instanced menthods and properties
    get default_options(): NotificationOptions {
        return {
            delay: 0, 
            life: 5000, 
            level: "info", 
            position: "top", 
            can_close: true
        }
    }
    
    private constructor() {
    }

    show_toast(ele: HTMLElement | string, options?: NotificationOptions) {
        // This makes every option optional
        options = Object.assign(this.default_options, options)
        if (typeof ele == "string") {
            this.show_toast_message(ele, options);
        } else {
            this.show_toast_element(ele, options);
        }
    }

    private show_toast_message(txt: string, options: NotificationOptions) {
        this.show_toast_element(document.createTextNode(txt), options);
    }

    private show_toast_element(ele: HTMLElement | Text, options: NotificationOptions) {
        let toast = new NotificationToast();
        toast.append(ele);
        toast.setAttribute("level", options.level);
        toast.setAttribute("position", options.position);
        toast.setAttribute("can-close", options.can_close + "");
        toast.setAttribute("position-offset", "0");
        toast.setAttribute("life", options.life.toString());
        toast.setAttribute("delay", options.delay.toString());
        toast.update();// Update to match new attributes
        document.body.append(toast);
        toast.addEventListener("change", () => this.update_toasts());
        // Show this toast
        setTimeout((() => {
            toast.show();
        }).bind(this), 300);
        this.update_toasts();
    }

    dismiss_toast(ele: HTMLElement) {
        // Give the toast some time to finish animating
        // then remove it from the DOM
        setTimeout((() => {
            ele.remove();
            this.update_toasts();
        }).bind(this), 600);
        this.update_toasts();
    }

    private update_toasts() {
        // Update other toast's positions
        let other = Array.from(document.querySelectorAll("afw-notification-toast")) as NotificationToast[];
        other = other.reverse();
        let offsets = {
            "top": 0,
            "bottom": 0,
            "left": 0,
            "right": 0,
        } as Record<string, number>;
        for (const t of other) {
            let direction = Directions[t.getAttribute("position") as NotificationPositions];
            let current_offset = offsets[direction];
            // Update the offset but only if the notification is "shown"
            if (t.classList.contains(`${style_base}notification-toast-show`)) {
                t.style.setProperty(direction, current_offset + "px");
                let offset;
                // This adjusts where the offset is calculated from
                switch (OffsetSides[direction]) {
                    case "clientHeight":
                        offset = t.clientHeight;
                        break;
                    case "clientWidth":
                        offset = t.clientWidth;
                        break;
                }
                offsets[direction] += offset;
                offsets[direction] += 16; // This is for epic margins
            } else {
                // Hide it to the top
                t.style.setProperty(direction, "-" +  (2 * t.clientHeight) + "px");
            }
        }
    }

    show_banner(element: HTMLElement, position: "top" | "bottom") {
        let banner = new NotificationBanner();
        banner.setAttribute("position", position);
        banner.update();
        banner.append(element);
        document.body.append(banner);
        // Show this banner
        setTimeout((() => {
            banner.show();
        }).bind(this), 300);
    }

    dismiss_banner(element: HTMLElement) {
        // Give the banner some time to finish animating
        // then remove it from the DOM
        setTimeout((() => {
            element.remove();
        }).bind(this), 600);
    }
    
    show_alert(element: HTMLElement | string, options: NotificationOptions) {
        options = Object.assign(this.default_options, options);
        options.life = 247000000;
        this.show_toast(element, options);
    }

    dismiss_alert(element: HTMLElement) {
        this.dismiss_toast(element);
    }
}

export type NotificationPositions = 
    "top-left" |
    "top" |
    "top-right" |
    "right-top" |
    "right" |
    "right-bottom" |
    "bottom-right" |
    "bottom" |
    "bottom-left" |
    "left-bottom" |
    "left" |
    "left-top" |
    "center"

export type NotificationLevels = 
    "primary" |
    "secondary" |
    "success" | 
    "info" | 
    "danger" |
    "warning" |
    "error" |
    "none"

export type NotificationOptions = {
    delay?: number,
    life?: number,
    level?: NotificationLevels,
    position?: NotificationPositions,
    can_close?: boolean
}

// Utility consts

const Directions = {
    "top-left":     "top",
    "top":          "top",
    "top-right":    "top",
    "right-top":    "right",
    "right":        "right",
    "right-bottom": "right",
    "bottom-right": "bottom",
    "bottom":       "bottom",
    "bottom-left":  "bottom",
    "left-bottom":  "left",
    "left":         "left",
    "left-top":     "left",
    "center":       "top"
} as Record<NotificationPositions, string>;

const OffsetSides = {
    "top": "clientHeight",
    "right": "clientWidth",
    "bottom": "clientHeight",
    "left": "clientWidth",
} as Record<string, string>