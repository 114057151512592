import { user_is_on_mobile } from "@src/common/util/dom_extensions";
import "@styles/common/templates/modals/basic_modal.sass";
import AbstractModal from "./abstract_modal";

export default class BasicModal extends AbstractModal {
    constructor() {
        super();
        this.classList.add("afw-modal", "afw-basic-modal");
        this.show = this.show.bind(this);
        this.hide = this.hide.bind(this);
        this.createBody();
        if (user_is_on_mobile()) {
            this.classList.add("afw-mobile");
        }
    }

    createBody() {
        let mbody = document.createElement("div");
        mbody.classList.add("afw-modal-body");
        this.append(mbody);
        let footer = document.createElement("div");
        footer.classList.add("afw-modal-footer");
        this.append(footer);
    }
}

customElements.define("afw-basic-modal", BasicModal);