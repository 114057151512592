import "@styles/common/templates/pie_chart.sass";
import { style_base } from "../common";

class ProgressRing extends HTMLElement {
    static get observedAttributes() {
        return [
            "stroke",
            "radius",
            "progress",
        ]
    }

    set stroke(val: string) {
        this.setAttribute("stroke", parseInt(val).toString() || "0");
    }
    get stroke() {
        return this.getAttribute("stroke") || "0";
    }
    set radius(val: string) {
        this.setAttribute("radius", parseInt(val).toString() || "0");
    }
    get radius() {
        return this.getAttribute("radius") || "0";
    }
    set progress(val: string) {
        this.setAttribute("progress", parseInt(val).toString() || "0");
    }
    get progress() {
        return this.getAttribute("progress") || "0";
    }

    constructor() {
        super();
        this.classList.add(style_base+ "progress-ring");
        let svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
        { // Background wheel
            let background_circle = document.createElementNS("http://www.w3.org/2000/svg", "circle");
            background_circle.setAttribute("height", parseInt(this.radius) * 2 + "px");
            background_circle.setAttribute("width", parseInt(this.radius) * 2 + "px");
            const normalizedRadius = parseInt(this.radius) - parseInt(this.stroke) * 2;
            background_circle.style.stroke = "#fafafa";
            background_circle.style.strokeWidth = this.stroke;
            background_circle.style.fill = "transparent";
            background_circle.setAttribute("r",`${normalizedRadius}`);
            background_circle.setAttribute("cx", this.radius);
            background_circle.setAttribute("cy", this.radius);
            svg.append(background_circle);
        }
        { // Actual progress wheel
            let progress_circle = document.createElementNS("http://www.w3.org/2000/svg", "circle");
            progress_circle.classList.add(style_base + "progress-circle-svg");
            svg.append(progress_circle);
        }
        this.append(svg);
    }

    attributeChangedCallback(name: string, old_value: string, new_value: string) {
        this.update_progress();
    }
    
    update_progress() {
        let radius = parseInt(this.radius);
        let stroke = parseInt(this.stroke);
        let progress = parseInt(this.progress);
        const normalizedRadius = radius - stroke * 2;
        let base = (normalizedRadius * 2 * Math.PI);
        let offset = base - progress / 100 * base;
        let svg = this.querySelector("svg");
        let circle = this.querySelector(`.${style_base}progress-circle-svg`) as SVGCircleElement;
        if (svg == undefined || circle == undefined) {
            return
        }
        svg.setAttribute("height", radius * 2 + "px");
        svg.setAttribute("width", radius * 2 + "px");
        circle.style.stroke = "green";
        circle.style.strokeDasharray = `${base} ${base}`;
        circle.style.strokeDashoffset = offset.toString();
        circle.style.strokeWidth = stroke.toString();
        circle.style.fill = "transparent";
        circle.setAttribute("r",`${normalizedRadius}`);
        circle.setAttribute("cx",`${radius}`);
        circle.setAttribute("cy",`${radius}`);
    }
}

customElements.define('afw-progress-ring', ProgressRing);

export default ProgressRing