/**
 * This file is loaded on all views, it contains common functionality
 * bettween views
 */
import "@styles/common/index.sass";
import { prefix } from "@styles/styles.json";
import $ from "./util/fake_jquery";

// LOADING AND RESIZING INITIALIZERS

// With this other files can add functions to the load
window.load_functions = []
window.onload = () => {
    for (let i = 0; i < window.load_functions.length; i++) {
        const load_f = window.load_functions[i];
        try {
            load_f();
        } catch (error) {
            console.error(error);
        }
    }
}

// And this for functions on resize
window.resize_functions = []
window.onresize = () => {
    for (let i = 0; i < window.resize_functions.length; i++) {
        const resize_f = window.resize_functions[i];
        resize_f();
    }
}

// This to show the page when the DOM is finished loading
let ready_listener = () => {
    if (document.readyState === "complete" && document.querySelector("body").style.visibility == "hidden") {
        document.querySelector("body").style.visibility = "visible";
        document.removeEventListener("readystatechange", ready_listener);
    }
}

document.addEventListener("readystatechange", ready_listener);

export { 
    $,
    prefix as style_base,
}