import { style_base } from "@src/common";

/**
 * Hides and shows the containers inside depending on the selected attribute
 * 
 * @example ```html
 * <stk-selective-container selected="0" shown-class="shown" hidden-class="hidden">
 *     <p class="p-2">Selective Container 1</p>
 *     <p class="p-2">Selective Container 2</p>
 *     <p class="p-2">Selective Container 3</p>
 *     <p class="p-2">Selective Container 4</p>
 *     <p class="p-2">Selective Container 5</p>
 * </stk-selective-container>```
 */
export default class SelectiveContainer extends HTMLElement {
    static get observedAttributes() {
        return [
            "selected",
            "shown-class",
            "hidden-class"
        ]
    }

    set selected(val: string) {
        // Check if the selected item exists
        let parsed = parseInt(val || "0");
        if (parsed > this.children.length) {
            // If it doesn't, destroy the child corrupt them all
            parsed = 0;
        }
        this.setAttribute("selected", parsed.toString(10));
    }
    get selected() {
        return this.getAttribute("selected") || "0";
    }
    set shownClass(val: string) {
        this.setAttribute("shown-class", val);
    }
    get shownClass() {
        return this.getAttribute("shown-class") || "shown";
    }
    set hiddenClass(val: string) {
        this.setAttribute("hidden-class", val);
    }
    get hiddenClass() {
        return this.getAttribute("hidden-class") || "hidden";
    }

    constructor() {
        super();
        this.updateSelected = this.updateSelected.bind(this);
        this.initialize     = this.initialize.bind(this);
        this.classList.add(style_base + "selective-container");
        // Initial state is "onMouseLeave"
        this.initialize();
    }

    attributeChangedCallback(name: string, oldValue: string, newValue: string) {
        if (name === "selected") {
            this.updateSelected(oldValue, newValue);
        }
    }

    initialize() {
        for (let i = 0; i < this.children.length; i++) {
            const child = this.children[i];
            child.classList.add(...this.hiddenClass.split(" "));
        }
        // Show the selected one (default is the first one)
        let selected = parseInt(this.selected);
        this.children[selected].classList.remove(this.hiddenClass);
        this.children[selected].classList.add   (this.shownClass);
    }

    updateSelected(oldValue: string, newValue: string) {
        let parsedOld = parseInt(oldValue);
        let parsedNew = parseInt(newValue);
        if (oldValue !== newValue && oldValue != undefined && 0 <= parsedOld && parsedOld < this.children.length && !isNaN(parsedOld)) {
            if (parsedNew > this.children.length || parsedNew <= 0 || isNaN(parsedNew)) {
                // If it doesn't, destroy the child corrupt them all
                parsedNew = 0;
            }
            this.children[parsedOld].classList.remove(this.shownClass);
            this.children[parsedOld].classList.add   (this.hiddenClass);
            this.children[parsedNew].classList.remove(this.hiddenClass);
            this.children[parsedNew].classList.add   (this.shownClass);
        }
    }
}

customElements.define("afw-selective-container", SelectiveContainer);