import { style_base } from "@src/common";
import "@styles/common/templates/skeletons/skeleton.sass";

/**
 * Makes a skeleton that when seen will load an image only when it is seen by
 * the user.
 * 
 * @example ```html
 * <img-skeleton src="images/example1.png">
 * </img-skeleton>
 * ```
 * 
 * @example ```html
 * <!-- If "auto-load" is included the skeleton will be loaded automatically -->
 * <img-skeleton src="images/example1.png" auto-load>
 * </img-skeleton>
 * ```
 * 
 * @example ```html
 * <!-- The "img-class" attribute will set the class of the image element created -->
 * <img-skeleton img-class="img-thumbnail" src="images/example1.png">
 * </img-skeleton>
 * ```
 * 
 * @example ```html
 * <img-skeleton 
 *  src="
 *      images/example1_400px.png"
 *  srcset="
 *      images/example1_400px.png 400w,
 *      images/example1_600px.png 600w,
 *      images/example1_800px.png 800w"
 *  sizes="
 *      (min-width: 960px) 400px,
 *      100vw">
 * </img-skeleton>
 * ```
 */
export default class IMGSkeleton extends HTMLElement {
    static get observedAttributes() {
        return [
            "src",
            "disabled",
            "img-class"
        ]
    }

    set src(val: string) {
        if (val === "") {
            console.error("A src attribute is required for a img-skeleton");
        }
        this.setAttribute("src", val);
    }
    get src() {
        return this.getAttribute("src") || "";
    }
    set srcSet(val: string) {
        this.setAttribute("srcset", val);
    }
    get srcSet() {
        return this.getAttribute("srcset") || "";
    }
    set sizes(val: string) {
        this.setAttribute("sizes", val);
    }
    get sizes() {
        return this.getAttribute("sizes") || "";
    }
    set imgClass(val: string) {
        this.setAttribute("img-class", val);
    }
    get imgClass() {
        return this.getAttribute("img-class") || "img";
    }

    constructor() {
        super();
        this.load = this.load.bind(this);
        this.load_img = this.load_img.bind(this);
        this.classList.add(style_base + "img-skeleton");
        this.classList.add(style_base + "loading");
    }

    connectedCallback() {
        if (this.children.length == 0 && !this.hasAttribute("no-back")) {
            let background = document.createElement("div");
            background.classList.add(style_base + "fill");
            this.append(background);
        }
        if (this.hasAttribute("auto-load")) {
            this.querySelector("div").remove();
            this.load_img();
        } else {
            let observer = new IntersectionObserver(this.load, {threshold: 0, rootMargin: "8px"});
            observer.observe(this);
        }
    }

    load(entries: IntersectionObserverEntry[], observer: IntersectionObserver) {
        let visibility = entries[0].intersectionRatio;
        let self = entries[0].target as IMGSkeleton;
        if (visibility > 0 && self.classList.contains(style_base + "loading") && !self.hasAttribute("disabled")) {
            this.load_img();
            // Clean up the observer, it isn't needed anymore
            observer.disconnect();
        }
    }

    private load_img() {
        let img = new Image();
        img.classList.add("img");
        if (this.imgClass != "") {
            img.classList.add(...this.imgClass.split(" "));
        }
        img.src = this.src;
        img.srcset = this.srcSet;
        img.sizes = this.sizes;
        let load_handler = () => {
            // Clean up and set the image
            this.innerHTML = "";
            this.append(img);
            this.classList.remove(style_base + "loading");
            img.removeEventListener("load", load_handler);
        }
        let error_handler = () => {
            img.removeEventListener("error", error_handler);
        }
        img.addEventListener("load", load_handler);
        img.addEventListener("error", error_handler);
    }
}

customElements.define("afw-img-skeleton", IMGSkeleton);