import ModalProviderAPI from "@src/common/providers/modal_provider";
import "@styles/common/templates/provider.sass";

export default class ModalProviderAPIElement extends HTMLElement {
    constructor() {
        super();
        this.getInstance = this.getInstance.bind(this);
        this.classList.add("api-provider-ele");
    }

    public getInstance(): ModalProviderAPI {
        return ModalProviderAPI.getInstance();
    }
}

customElements.define("afw-modal-provider-api", ModalProviderAPIElement);