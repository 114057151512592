import { style_base } from "@src/common";
import "@styles/common/templates/expandables/accordion.sass";

export default class Accordion extends HTMLElement{
    constructor() {
        super();
        this.classList.add(`${style_base}accordion`);
        // Create the checkbox for CSS magic
        let checkbox = document.createElement("input");
        checkbox.type = "checkbox";
        checkbox.classList.add(`${style_base}hidden-checkbox`);
        this.prepend(checkbox);
        this.append(document.createElement("i"));
    }
}

customElements.define("afw-accordion", Accordion);
