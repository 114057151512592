import { timeBetween } from "@src/common/util/time";
import "@styles/common/templates/misc/countdown.sass";

export default class Countdown extends HTMLElement {
    public get targetDate() : Date {
        return this.getAttribute("target") ? new Date(this.getAttribute("target")) : new Date();
    }
    /** @deprecated - not implemented */
    public get format() : string {
        return this.getAttribute("format") || "YYYY:MM:DD:HH:m:s";
    }
    
    constructor() {
        super();
        this.classList.add("afw-countdown");
    }

    connectedCallback() {
        this.innerHTML = `
        <span class="numbers">
            <span class="days">0</span>
            <span class="hours">0</span>
            <span class="minutes">0</span>
            <span class="seconds">0</span>
        </span>
        `;
        this.handleUpdate();
    }

    handleUpdate() {
        let now = new Date();
        let targetDate = this.targetDate;
        let duration = timeBetween(now, targetDate);
        this.querySelector(".numbers .days").textContent = duration.days + "";
        this.querySelector(".numbers .hours").textContent = duration.hours + "";
        this.querySelector(".numbers .minutes").textContent = duration.minutes + "";
        this.querySelector(".numbers .seconds").textContent = duration.seconds + "";
        setTimeout(() => {
            this.handleUpdate()
        }, 1000);
    }
}

customElements.define("afw-countdown", Countdown);