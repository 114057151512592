import { style_base } from "@src/common";
import "@styles/common/templates/async_containers/load_more.sass";

/**
 * Extends to `<afw-load-async>` but adds a "Load More" button at the tail of 
 * the container and content added will be inserted before that button.
 */
export default class LoadMoreContainer extends HTMLElement{
    static get observedAttributes() {
        return [
            "load-now"
        ]
    }

    set src(val: string) {
        this.setAttribute("src", val || `?load-more=true`);
    }
    get src() {
        return this.getAttribute("src") || `?load-more=true`;
    }
    get is_paginated(): boolean {
        return this.hasAttribute("paginated");
    }
    get pagination_key(): string {
        return this.getAttribute("paginated") || "page";
    }
    set page(val: number) {
        this.setAttribute("page", val.toFixed(0));
    }
    get page(): number {
        return parseInt(this.getAttribute("page") || "0");
    }
    get no_button(): boolean {
        return this.hasAttribute("no-btn");
    }
    get custom_loader(): boolean {
        return this.hasAttribute("custom-loader");
    }

    constructor() {
        super();
        this.load_more      = this.load_more.bind(this);
        this.setup_on_seen  = this.setup_on_seen.bind(this);
        this.classList.add(`${style_base}load-more`);
        // Create the checkbox for CSS magic
        let button = this.querySelector(`.${style_base}load-more-btn`) as HTMLButtonElement;
        if (button == null && !this.no_button) {
            button = document.createElement("button");
            button.classList.add(`${style_base}load-more-btn`);
            button.textContent = "Load More"
        }
        if (button != null) {
            button.addEventListener("click", this.load_more)
            this.append(button);
        }
        if (this.hasAttribute("run-onload") || this.hasAttribute("load-now")) {
            this.load_more();
        }
        if (this.hasAttribute("run-onseen")) {
            this.setup_on_seen();
        }
    }

    attributeChangedCallback(name: string, oldValue: string, newValue: string) {
        switch (name) {
            case "load-now":
                this.load_more();
                break;
        
            default:
                break;
        }
    }

    private setup_on_seen() {
        let observer = new IntersectionObserver((
            (entries: IntersectionObserverEntry[], observer: IntersectionObserver) => {
                let visibility = entries[0].intersectionRatio;
                if (visibility > 0) {
                    this.load_more();
                    observer.disconnect();
                }
            }
        ).bind(this), { threshold: 0, rootMargin: "8px" });
        observer.observe(this);
    }

    async load_more() {
        let button = null;
        if (!this.no_button) {
            button = this.querySelector(`.${style_base}load-more-btn`) as HTMLButtonElement;
            button.classList.add(`${style_base}load-more-btn-loading`);
        }
        let url;
        try {
            let real_url = new URL(this.src);
            url = real_url.protocol + "//" + real_url.hostname + real_url.pathname
        } catch (error) {
            url = this.src;
        }
        let query;
        try {
            query = new URL(this.src).searchParams;
        } catch (error) {
            query = new URLSearchParams();
        }
        if (this.is_paginated) {
            this.page++;
            query.set(this.pagination_key, this.page.toFixed(0));
        }
        let response;
        try {
            response = await fetch(url + "?" + query.toString());
            if (!response.ok) throw new Error("Reviews could not be obtained");
        } catch (error) {
            if (this.custom_loader) {
                return this.dispatchEvent(new CustomEvent<LoadMoreCustomEvent>("customerror", {detail: {response}}));
            }
        }
        if (this.custom_loader) {
            return this.dispatchEvent(new CustomEvent<LoadMoreCustomEvent>("customload", {detail: {response}}));
        }
        let txt = await response.text();
        let element = document.createElement("div");
        element.innerHTML = txt;
        if (button != null) {
            this.insertBefore(element, button);
            button.classList.remove(`${style_base}load-more-btn-loading`);
        } else {
            this.append(element);
        }
    }
}

export interface LoadMoreCustomEvent {
    response: Response
}

customElements.define("afw-load-more", LoadMoreContainer);