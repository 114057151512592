import { user_is_on_mobile, user_is_on_opera, user_is_on_safari } from "@src/common/util/dom_extensions";
import "@styles/common/templates/modals/sheet_modal.sass";
import AbstractModal from "./abstract_modal";

export default class SheetModal extends AbstractModal {
    constructor() {
        super();
        this.classList.add("afw-modal", "afw-sheet-modal");
        this.show = this.show.bind(this);
        this.hide = this.hide.bind(this);
        this.createBody();
        if (user_is_on_mobile()) {
            this.classList.add("afw-mobile");
        }
        if (user_is_on_safari()) {
            this.classList.add("afw-sheet-modal-safari");
        }
        if (user_is_on_opera()) {
            this.classList.add("afw-sheet-modal-opera");
        }
    }

    createBody() {
        let footer = document.createElement("div");
        footer.classList.add("afw-modal-footer"); // Actually the top part
        this.append(footer);
        let mbody = document.createElement("div");
        mbody.classList.add("afw-modal-body");
        this.append(mbody);
    }
}

customElements.define("afw-sheet-modal", SheetModal);