import { style_base } from "@src/common";
import "@styles/common/templates/async_containers/load_more.sass";

/**
 * A container that is meant to load asyncronously. This can be triggered by
 * adding the "load-now" attribute.
 * 
 * The content will be fetched from the url provided on the "src" attribute
 * and appended to the container.
 * 
 * It can also be triggered by adding other attributes to set the behavior of
 * loading:
 * 
 * - run-onload => This will make the container load the content on load of the
 *      DOM.
 * - run-onseen => This will load the content when the container is seen in any
 *      capacity by the user. When seen, the observer will be disposed, not
 *      executing this function again.
 * 
 * You can also listen to these events by providing a "onload" attribute just
 * like you would with any other container.
 * 
 * @example ```html
 * <afw-load-async src="./load-async.html"></afw-load-async>
 * ```
 */
export default class LoadAsyncContainer extends HTMLElement{
    static get observedAttributes() {
        return [
            "src",
            "load-now"
        ]
    }

    set src(val: string) {
        this.setAttribute("src", val || `?load-async=true`);
    }
    get src() {
        return this.getAttribute("src") || `?load-async=true`;
    }
    
    get replace_inner(): boolean {
        return this.hasAttribute("replace-inner");
    }
    
    constructor() {
        super();
        this.load_more      = this.load_more.bind(this);
        this.setup_on_seen  = this.setup_on_seen.bind(this);
        if (this.hasAttribute("run-onload") || this.hasAttribute("load-now")) {
            this.load_more();
        }
        if (this.hasAttribute("run-onseen")) {
            this.setup_on_seen();
        }
    }

    attributeChangedCallback(name: string, oldValue: string, newValue: string) {
        switch (name) {
            case "load-now":
                this.load_more();
                break;
        
            default:
                break;
        }
    }

    private setup_on_seen() {
        let observer = new IntersectionObserver((
            (entries: IntersectionObserverEntry[], observer: IntersectionObserver) => {
                let visibility = entries[0].intersectionRatio;
                if (visibility > 0) {
                    this.load_more();
                    observer.disconnect();
                }
            }
        ).bind(this), { threshold: 0, rootMargin: "8px" });
        observer.observe(this);
    }

    async load_more() {
        let url = this.src;
        let response = await fetch(url);
        if (response.ok) {
            let txt = await response.text();
            if (this.replace_inner) {
                this.innerHTML = "";
            }
            this.innerHTML = txt;
            if (this.onload !== undefined && typeof this.onload == "function") {
                this.onload(new Event("onload", {}));
            }
        }
    }
}

customElements.define("afw-load-async", LoadAsyncContainer);