import "@styles/common/templates/loading_bar.sass";
import { style_base, $ } from "../common";

class LoadingBar extends HTMLElement {
    static get observedAttributes() {
        return [
            "progress",
            "width",
            "height",
            "vertical",
            "colour",
            "background",
        ]
    }

    set progress(val: string) {
        this.setAttribute("progress", parseInt(val).toString() || "0");
    }
    get progress() {
        return this.getAttribute("progress") || "0";
    }
    set width(val: string) {
        this.setAttribute("width", val || "0");
    }
    get width() {
        return this.getAttribute("width") || "0";
    }
    set height(val: string) {
        this.setAttribute("height", val || "0");
    }
    get height() {
        return this.getAttribute("height") || "0";
    }
    set vertical(val: string) {
        this.setAttribute("vertical", val);
    }
    get vertical() {
        return this.getAttribute("vertical") || "false";
    }
    set colour(val: string) {
        this.setAttribute("colour", val);
    }
    get colour() {
        return this.getAttribute("colour") || "none";
    }
    set background(val: string) {
        this.setAttribute("background", val);
    }
    get background() {
        return this.getAttribute("background") || "none";
    }

    constructor() {
        super();
        this.classList.add(style_base + "loading-bar");
        this.classList.add("block", "relative");
        // The inside of the bar is the actual progress
        let inside = document.createElement("div");
        inside.classList.add(style_base + "bar-inside");
        inside.classList.add("absolute");
        inside.style.width = (this.vertical == "true" ? this.width : "0");
        inside.style.height = (this.vertical == "true" ? "0" : this.height);
        if (this.colour != "none") {
            inside.style.backgroundColor = this.colour;
        }
        // The outside it the background
        let outside = document.createElement("div");
        outside.classList.add(style_base + "bar-outside");
        outside.classList.add("absolute");
        outside.style.width = this.width;
        outside.style.height = this.height;
        if (this.background != "none") {
            outside.style.backgroundColor = this.background;
        }
        // Outside is appended first so it's painted behind the inside
        this.append(outside);
        this.append(inside);
        this.update_progress();
    }

    attributeChangedCallback(name: string, old_value: string, new_value: string) {
        this.update_progress();
        this.update_opacity();
        if (name == "width" || name == "height") {
            this.update_size();
        }
    }

    update_progress() {
        let inside = this.querySelector(`.${style_base}bar-inside`) as HTMLElement;
        let outside = this.querySelector(`.${style_base}bar-outside`) as HTMLElement;
        // Refers to the width and height measurement unit
        let unit_regex = /([^0-9])/gi;
        let width_unit = (this.width.match(unit_regex) || ["px"]).join("");
        let height_unit = (this.height.match(unit_regex) || ["px"]).join("");
        let scale = parseInt(this.progress) / 100;
        if (this.vertical == "true") {
            let max_height = parseInt(this.height);
            inside.style.height = Math.round(scale * max_height).toString() + height_unit;
        } else {
            let max_width = parseInt(this.width);
            inside.style.width = Math.round(scale * max_width).toString() + width_unit;
        }
    }

    update_opacity() {
        let progress = parseInt(this.progress);
        // if the progress is between 0 and 100 then it is loading
        if (0 < progress && progress < 100) {
            let already_loading = this.classList.contains(style_base + "loading");
            if (!already_loading) {
                this.classList.add("loading");
            }
        } else {
            this.classList.remove("loading");
        }
    }

    update_size() {
        let inside = this.querySelector(`.${style_base}bar-inside`) as HTMLElement;
        let outside = this.querySelector(`.${style_base}bar-outside`) as HTMLElement;
        // Refers to the width and height measurement unit
        let unit_regex = /([^0-9])/gi;
        let width_unit = (this.width.match(unit_regex) || ["px"]).join("");
        let height_unit = (this.height.match(unit_regex) || ["px"]).join("");
        if (this.vertical == "false") {
            let max_height = parseInt(this.height);
            inside.style.height = Math.round(max_height).toString() + height_unit;
        } else {
            let max_width = parseInt(this.width);
            inside.style.width = Math.round(max_width).toString() + width_unit;
        }
        outside.style.width = this.width;
        outside.style.height = this.height;
    }
}

// LOADING BAR RELATED METHODS

let start_loading = (id?: string) => {
    update_loading(1, id);
}

/** @param new_progress Integer percentage */
let update_loading = (new_progress: number, id?: string) => {
    let loading_bar = get_loading_bar(id) as (NodeListOf<HTMLElement> | HTMLElement);
    if (loading_bar) {
        if (loading_bar instanceof NodeList) {
            for (let index = 0; index < loading_bar.length; index++) {
                const element = loading_bar[index];
                update_loading_bar(element, new_progress);
            }
        } else {
            update_loading_bar(loading_bar, new_progress);
        }
    }
}

let get_loading_bar = (id?: string) => {
    if (id) {
        return $(`.${style_base}loading-bar` + id);
    }
    return $(`.${style_base}loading-bar`);
}

let update_loading_bar = (bar: HTMLElement, new_progress: number) => {
    bar.setAttribute("progress", new_progress.toString());
}

let stop_loading = (id?: string) => {
    update_loading(100, id);
    setTimeout(() => {
        update_loading(0, id);
    }, 2000)
}

customElements.define("afw-loading-bar", LoadingBar);

export default LoadingBar;
export { stop_loading, update_loading, start_loading };