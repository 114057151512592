import "@styles/common/templates/misc/tooltip.sass";
import Portal from "../portal";

export default class Tooltip extends HTMLElement {
    mouseInParent: boolean;
    mouseInTooltip: boolean;
    connected: boolean;
    
    public get tooltipParent() : HTMLElement {
        return document.querySelector(this.getAttribute("for"));
    }
    public get position() : TooltipPositions {
        return this.getAttribute("position") as TooltipPositions || "on-top";
    }
    public get tooltipOffset() : number {
        return parseInt(this.getAttribute("offset") || "8");
    }
    public get isManual() : boolean {
        return this.hasAttribute("manual");
    }
    
    constructor() {
        super();
        this.classList.add("afw-tooltip");
        this.connected = false;
    }

    connectedCallback() {
        if (this.isManual) return;
        this.init();
    }

    init() {
        let tooltipContainer = Portal.getInstance().tooltipContainer;
        if (!tooltipContainer.contains(this)) {
            Portal.getInstance().tooltipContainer.append(this);
            return;
        }
        if (!this.connected) {
            let parent = this.tooltipParent;
            parent.addEventListener("mouseenter", () => {
                this.mouseInParent = true;
                this.show();
            });
            parent.addEventListener("mouseleave", () => {
                this.mouseInParent = false;
                this.handleMouseOut();
            });
            this.addEventListener("mouseenter", () => {
                this.mouseInTooltip = true;
            });
            this.addEventListener("mouseleave", () => {
                this.mouseInTooltip = false;
                this.handleMouseOut();
            });
        }
    }

    handleMouseOut() {
        setTimeout(() => {
            if (this.mouseInParent || this.mouseInTooltip) {
                return;
            }
            this.hide();
        }, 100);
    }

    show() {
        // Set the as shown
        this.classList.add("shown");
        // Hack to make css update before function executes
        setTimeout(() => {
            this.updatePosition();
        }, 0);
    }

    updatePosition() {
        let [centerX, centerY] = this.getAnchorPos();
        let [offsetX, offsetY] = this.getOffset();
        let destX = centerX + offsetX;
        let destY = centerY + offsetY;
        this.style.left = destX + "px";
        this.style.top  = destY + "px";
        this.classList.add("visible");
        this.classList.add(this.position);
        this.dispatchEvent(new CustomEvent("shown"));
    }

    getAnchorPos() {
        let parent = this.tooltipParent;
        let parentRect = parent.getBoundingClientRect();
        let parentX = parentRect.left;
        let parentY = parentRect.top;
        switch (this.position) {
            case "on-top":
                return [parentX + (parentRect.width >> 1), parentY + 0];
            case "on-bottom":
                return [parentX + (parentRect.width >> 1), parentY + parentRect.height];
            case "on-left":
                return [parentX + 0                      , parentY + (parentRect.height >> 1)];
            case "on-right":
                return [parentX + parentRect.width       , parentY + (parentRect.height >> 1)];
        }
    }

    getOffset() {
        switch (this.position) {
            case "on-top":
                return [-this.clientWidth >> 1                   , -this.clientHeight  - this.tooltipOffset];
            case "on-bottom":
                return [-this.clientWidth >> 1                   , 0                   + this.tooltipOffset];
            case "on-left":
                return [-this.clientWidth    - this.tooltipOffset, -this.clientHeight >> 1];
            case "on-right":
                return [0                    + this.tooltipOffset, -this.clientHeight >> 1];
        }
    }

    hide() {
        this.classList.remove("visible");
        this.classList.remove("shown");
        this.dispatchEvent(new CustomEvent("hidden"));
    }
}

customElements.define("afw-tooltip", Tooltip);

type TooltipPositions = "on-top" | "on-bottom" | "on-left" | "on-right";