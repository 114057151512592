import { generateString } from "../util/generators";
import $ from "../util/fake_jquery";

import "@styles/common/templates/sticky_nav.sass";
import { style_base } from "../common";
import { user_is_on_mobile } from "../util/dom_extensions";

/**
 * Sticky nav bar
 * This nav bar clones a copy of it onto the overlay of the page. It then is
 * only shown there when the user stops seeing the original navbar.
 * 
 * @example ```html
 * <!-- If the `sticky-class` is ommitted, the default will be "shown" -->
 * <sticky-nav sticky-class="shown">
 *      <h1>Header lol</h1>
 * </sticky-nav>
 * ```
 */
class StickyNav extends HTMLElement {
    static get observedAttributes() {
        return [
            "sticky-class",
            "top",
            "right",
            "bottom",
            "left",
            "from-top",
            "from-right",
            "from-bottom",
            "from-left",
        ]
    }

    set stickyclass(val: string) {
        this.setAttribute("sticky-class", val || "shown");
    }
    get stickyclass() {
        return this.getAttribute("sticky-class") || "shown";
    }

    last_scroll: number;
    observer: IntersectionObserver;

    get getNav() {
        return $(`.afw-sticky-nav-overlay[data-nav_id="${this.dataset.nav_id}"]`) as HTMLElement;
    }

    constructor() {
        super();
        // Remind the scroll handler who the boss is
        this.scroll_handler = this.scroll_handler.bind(this);
        this.toggle_fixed   = this.toggle_fixed.bind(this);
        // This is to avoid carousel collisions
        let random_id = generateString(16);
        this.classList.add(style_base + "sticky-nav");
        this.dataset.nav_id = random_id;
        this.observer = new IntersectionObserver(this.toggle_fixed, {threshold: 0, rootMargin: "8px"});
        this.observer.observe(this);
        let overlay = $("body") as HTMLElement;
        if (overlay == undefined) {
            console.error("Could not setup sticky nav bar");
            return;
        }
        // Create a clone to add to the overlay
        let nav_clone = document.createElement("div");
        for (let i = 0; i < this.children.length; i++) {
            const element = this.children[i] as HTMLElement;
            nav_clone.append(element.cloneNode(true));
        }
        nav_clone.dataset.nav_id = random_id;
        nav_clone.classList.add(style_base + "sticky-nav-overlay");
        // Check if the positioning of the overlay attribuites
        if (this.hasAttribute("top"))           nav_clone.classList.add("top");
        if (this.hasAttribute("right"))         nav_clone.classList.add("right");
        if (this.hasAttribute("bottom"))        nav_clone.classList.add("bottom");
        if (this.hasAttribute("left"))          nav_clone.classList.add("left");
        if (this.hasAttribute("from-top"))      nav_clone.classList.add("from-top");
        if (this.hasAttribute("from-right"))    nav_clone.classList.add("from-right");
        if (this.hasAttribute("from-bottom"))   nav_clone.classList.add("from-bottom");
        if (this.hasAttribute("from-left"))     nav_clone.classList.add("from-left");
        overlay.prepend(nav_clone);
        // Check if the user is on mobile
        if (user_is_on_mobile()) {
            this.last_scroll = window.scrollY;
            window.addEventListener("scroll", this.scroll_handler.bind(this));
        }
    }

    scroll_handler(scrollEvent: Event) {
        let scroll = window.scrollY;
        if (this.last_scroll < scroll) {
            // User is going down the page
            this.getNav.classList.remove(this.stickyclass);
        } else {
            this.getNav.classList.add(this.stickyclass);
        }
        this.last_scroll = window.scrollY;
    }

    toggle_fixed(entries: IntersectionObserverEntry[], observer: IntersectionObserver) {
        // First one should be this element
        let visibility = entries[0].intersectionRatio;
        let the_clone = this.getNav;
        if (this instanceof StickyNav && the_clone != undefined) {
            if (visibility == 0) {
                this.classList.remove(this.stickyclass);
                the_clone.classList.add(this.stickyclass);
            } else {
                this.classList.add(this.stickyclass);
                the_clone.classList.remove(this.stickyclass);
            }
        }   
    }
}

customElements.define("afw-sticky-nav", StickyNav);

export default StickyNav;