import { style_base } from "@src/common";
import { getRandomArbitrary } from "@src/common/util/generators";
import "@styles/common/templates/skeletons/skeleton.sass";

/**
 * Makes a skeleton that when seen will pick a random image from the list given
 * 
 * @example ```html
 * <random-img-skeleton src="images/example1.png;!images/example2.png;!images/example3.png">
 * </random-img-skeleton>
 * ```
 * 
 * @example ```html
 * <!-- If "auto-load" is included the skeleton will be loaded automatically -->
 * <img-skeleton src="images/example1.png" auto-load>
 * </img-skeleton>
 * ```
 * 
 * @example ```html
 * <!-- The "img-class" attribute will set the class of the image element created -->
 * <random-img-skeleton img-class="img-thumbnail" src="images/example1.png;!images/example2.png;!images/example3.png">
 * </random-img-skeleton>
 * ```
 * 
 * @example ```html
 * <random-img-skeleton 
 *  src="
 *      images/example1_400px.png;!
 *      images/example2_400px.png"
 *  srcset="
 *      images/example1_400px.png 400w,
 *      images/example1_600px.png 600w,
 *      images/example1_800px.png 800w
 *      ;!
 *      images/example2_400px.png 400w,
 *      images/example2_600px.png 600w,
 *      images/example2_800px.png 800w"
 *  sizes="
 *      (min-width: 960px) 400px,
 *      100vw
 *      ;!
 *      (min-width: 960px) 400px,
 *      100vw
 *  ">
 * </random-img-skeleton>
 * ```
 */
export default class RandomIMGSkeleton extends HTMLElement {
    static get observedAttributes() {
        return [
            "src",
            "srcset",
            "sizes",
            "disabled",
            "img-class"
        ]
    }

    set src(val: string) {
        if (val === "") {
            console.error("A src attribute is required for a img-skeleton");
        }
        this.setAttribute("src", val);
    }
    get src() {
        return this.getAttribute("src") || "";
    }
    set srcSet(val: string) {
        this.setAttribute("srcset", val);
    }
    get srcSet() {
        return this.getAttribute("srcset") || "";
    }
    set sizes(val: string) {
        this.setAttribute("sizes", val);
    }
    get sizes() {
        return this.getAttribute("sizes") || "";
    }
    set imgClass(val: string) {
        this.setAttribute("img-class", val);
    }
    get imgClass() {
        return this.getAttribute("img-class") || "img";
    }

    constructor() {
        super();
        // Remind the loader who owns it
        this.load = this.load.bind(this);
        this.load_img = this.load_img.bind(this);
        // Add the style base
        this.classList.add(style_base + "img-skeleton");
        this.classList.add(style_base + "loading");
        let background = document.createElement("div");
        background.classList.add(style_base + "fill");
        this.append(background);
        if (this.hasAttribute("auto-load")) {
            this.querySelector("div").remove();
            this.load_img();
        } else {
            let observer = new IntersectionObserver(this.load, {threshold: 0, rootMargin: "8px"});
            observer.observe(this);
        }
    }

    load(entries: IntersectionObserverEntry[], observer: IntersectionObserver) {
        let visibility = entries[0].intersectionRatio;
        if (visibility > 0 && this.classList.contains(style_base + "loading") && !this.hasAttribute("disabled")) {
            this.querySelector("div").remove();
            this.load_img();
            // Clean up the observer, it isn't needed anymore
            observer.disconnect();
        }
    }

    private load_img() {
        let img = new Image();
        img.classList.add("img");
        img.classList.add(...this.imgClass.split(" "));
        // Split images into an array of images
        let img_src_array = this.src.split(";!");
        let img_set_array = this.srcSet.split(";!");
        let img_size_array = this.sizes.split(";!");
        // Get a random source image
        // Note: the random is rounded since there's no decimals in indexed arrays
        let index = Math.round(getRandomArbitrary(0, img_src_array.length - 1));
        img.src     = img_src_array[index].trim();
        if (img_set_array [index] != undefined && img_set_array [index].trim() != "") img.srcset  = img_set_array [index].trim();
        if (img_size_array[index] != undefined && img_size_array[index].trim() != "") img.sizes   = img_size_array[index].trim();
        let load_handler = (() => {
            // Clean up and set the image
            this.innerHTML = "";
            this.append(img);
            this.classList.remove(style_base + "loading");
            img.removeEventListener("load", load_handler);
        }).bind(this);
        let error_handler = (() => {
            img.removeEventListener("error", error_handler);
        }).bind(this);
        img.addEventListener("load", load_handler);
        img.addEventListener("error", error_handler);
    }
}

customElements.define("afw-random-img-skeleton", RandomIMGSkeleton);