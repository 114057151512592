import { style_base } from "@src/common";
import { generateString } from "@src/common/util/generators";
import Swiper, { EffectCards, Manipulation } from "swiper";

import 'swiper/css';
import 'swiper/css/effect-cards';
import "@styles/common/index.sass";

export default class CardCarousel extends HTMLElement {
    static get observedAttributes() {
        return [
            "card-class"
        ]
    }

    set cardClass(val: string) {
        this.setAttribute("card-class", val);
    }
    get cardClass() {
        return this.getAttribute("card-class") || "card";
    }

    constructor() {
        super();
        // This is to avoid carousel collisions
        let random_id = generateString(16);
        this.id = "my_swiper_" + random_id;
        this.classList.add(style_base + "swiper");
        this.classList.add(style_base + "flex", style_base + "align-centre");
        let wrapper = document.createElement("div");
        wrapper.classList.add("swiper-wrapper");
        // Get all children
        let slides = this.children;
        while (slides.length > 0) {
            // Move them out into the wrapper
            let slide_content = slides[0];
            let slide_wrapper = document.createElement("div");
            slide_wrapper.classList.add("swiper-slide");
            slide_wrapper.classList.add(...this.cardClass.split(" "));
            //slide_wrapper.classList.add(style_base);
            //slide_wrapper.classList.add("flex", "align-centre");
            //slide_wrapper.classList.add("rounded");
            //slide_content.classList.add(style_base, "fill")
            slide_wrapper.append(slide_content);
            wrapper.append(slide_wrapper);
        }
        this.append(wrapper);
        // Initialize the swiper
        let swiper = new Swiper("#" + this.id, {
            modules: [EffectCards, Manipulation],
            effect: "cards",
            grabCursor: true
        });
    }
}

customElements.define("afw-carousel-card",  CardCarousel);
