import "@styles/common/templates/notifications/banner.sass";
import { style_base } from "@src/common";
import NotificationProviderAPI, { NotificationPositions, NotificationLevels } from "@src/common/providers/notification_provider";
import { user_is_on_mobile } from "@src/common/util/dom_extensions";
import { generateString } from "@src/common/util/generators";

/*
 */
export default class NotificationBanner extends HTMLElement {
    static get observedAttributes() {
        return [
            "nothing",
        ]
    }

    get delay() {
        return parseInt(this.getAttribute("delay")) || 0;
    }
    get life() {
        return parseInt(this.getAttribute("life")) || -1;
    }
    get position(): ("top" | "bottom") {
        return this.getAttribute("position") == "top" ? "top" : "bottom";
    }
    get user_can_close() {
        return this.getAttribute("can-close") || "true" == "true";
    }

    constructor() {
        super();
        this.id = generateString(16);
        this.show  = this.show.bind(this);
        this.close = this.close.bind(this);
        this.update = this.update.bind(this);
        this.update();
    }

    attributeChangedCallback(_name: string, oldValue: string, newValue: string) {
        if (oldValue != newValue) {
            this.update();
        }
    }

    update() {
        this.classList.value = [
         `${style_base}notification-banner`,
         `${style_base}banner-pos-${this.position}`,
        ].join(" ");
        if (user_is_on_mobile()) {
            this.classList.add(`${style_base}mobile-device`);
        }
        if (this.user_can_close && this.querySelector(`.${style_base}banner-close-btn`) === null) {
            let button = document.createElement("a");
            button.classList.add(`${style_base}banner-close-btn`);
            this.append(button);
            button.addEventListener("click", () => this.close());
        }
    }

    show(now?: boolean) {
        if (this.delay > 0 && now != true) {
            setTimeout((() => this.show(true)).bind(this), this.delay);
            return;
        }
        this.classList.add(`${style_base}notification-banner-show`);
        this.dispatchEvent(new Event("change"));
    }

    close() {
        this.classList.remove(`${style_base}notification-banner-show`);
        NotificationProviderAPI.getInstance().dismiss_banner(this);
    }
}

customElements.define("afw-notification-banner", NotificationBanner);