import { wrap_in_anchor_with_id } from "@src/common/util/dom_extensions";
import { is_header_tag } from "@src/common/util/string_extensions";

export default class Article extends HTMLElement{
    constructor() {
        super();
        this.classList.add("afw-article");
        // Each direct header child gets a # link
        let child_count = this.children.length;
        let i = 0;
        while (child_count > i) {
            let child = this.children.item(i) as HTMLElement;
            if (is_header_tag(child.tagName)) {
                // Give the header an id
                wrap_in_anchor_with_id(child);
            }
            // This for section tags, only the first Header counts
            let is_section_tag = child.tagName == "SECTION";
            if (is_section_tag && child.children.length > 0) {
                let c = child.children.item(0) as HTMLElement;
                if (is_header_tag(c.tagName)) {
                    wrap_in_anchor_with_id(c);
                }
            }
            i++;
        }
    }
}

customElements.define("afw-article", Article);
