import "@styles/common/templates/portal.sass";
import { style_base } from '../common';
import { user_is_on_mobile, user_is_on_safari } from "../util/dom_extensions";

export default class Portal extends HTMLElement {
    public get isShown() : boolean {
        return this.classList.contains("shown");
    }

    public canvasOverlayContext: CanvasRenderingContext2D;
    public get canvasContainer() : HTMLDivElement {
        return this.querySelector(".canvas-overlay");
    }    
    public get tooltipContainer() : HTMLDivElement {
        return this.querySelector(".tooltip-container");
    }
    public get modalContainer() : HTMLDivElement {
        return this.querySelector(".modal-container");
    }
    public get sheetModalContainer() : HTMLDivElement {
        return this.querySelector(".sheet-modal-container");
    }
    public get pointerCatcher() : HTMLDivElement {
        return this.querySelector(".pointer-catcher");
    }
    public get background() : HTMLDivElement {
        return this.querySelector(".background");
    }
    /** Set the background HTML */
    public set background(v : HTMLDivElement) {
        this.background.innerHTML = v.outerHTML;
    }
    
    lastScroll: number;

    constructor() {
        super();
        this.classList.add("afw-portal");
        if (user_is_on_mobile()) {
            this.classList.add("afw-mobile");
        }
        if (user_is_on_safari()) {
            this.classList.add("safari");
        }
        this.show = this.show.bind(this);
        this.hide = this.hide.bind(this);
        this.toggle = this.toggle.bind(this);
        Portal.self = this;
    }

    connectedCallback() {
        this.innerHTML = `
            <canvas class="canvas-overlay"></canvas>
            <div class="tooltip-container"></div>
            <div class="modal-container"></div>
            <div class="sheet-modal-container"></div>
            <div class="pointer-catcher"></div>
            <div class="background"></div>
        `;
        let canvas = this.querySelector(".canvas-overlay") as HTMLCanvasElement;
        canvas.width = window.visualViewport.width;
        canvas.height = window.visualViewport.height;
        this.canvasOverlayContext = canvas.getContext("2d");
    }

    show() {
        this.classList.add("shown");
        this.classList.remove("hidden");
        // Remove ability to scroll
        let body = document.body;
        if (user_is_on_safari()) {
            // On safari, we go buck wild
            let current_scroll = window.scrollY;
            body.classList.add("portal-is-being-shown-safari");
            body.style.top = -current_scroll + "px";
            this.style.top = current_scroll + "px";
            this.style.position = "absolute";
            this.lastScroll = current_scroll;
        } else {
            body.classList.add("portal-is-being-shown");
        }
    }

    hide() {
        this.classList.add("hidden");
        this.classList.remove("shown");
        // Let the user be able to scroll again
        let body = document.body;
        if (user_is_on_safari()) {
            // On safari, we buckle our seats
            let root = document.querySelector(':root');
            body.classList.remove("portal-is-being-shown-safari");
            body.style.top = "";
            this.style.top = "";
            // Instant scroll instead of animated smooth scroll
            root.setAttribute("style", "scroll-behavior: auto;");
            // This timeout is kind of a dummy timeout. It's just so the DOM
            // has time to update the styles before scrolling
            setTimeout(() => {
                window.scrollTo(0, this.lastScroll);
                // Then we remove the scroll behavior so that the user can use
                // smooth scrolling again
                root.removeAttribute("style");
            }, 0)
        } else {
            body.classList.remove("portal-is-being-shown");
        }
    }

    toggle(show?: boolean) {
        if (this.isShown || (show !== undefined && show)) {
            this.hide();
        } else {
            this.show();
        }
    }

    private static self?: Portal;

    public static getInstance() {
        if (Portal.self === undefined) {
            Portal.self = new Portal();
            document.append(Portal.self);
        }
        return Portal.self;
    }
}

customElements.define("afw-portal", Portal);